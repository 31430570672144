:root {
	--delta-blue: #0061a0;
	--white: white;
	--delta-orange: #d45d00;
	--black: black;
	--3rd-bg: #fff0;
	--main-bg: #d6e2f0;
	--dark-delta: #004976;
	--secondary-bg: #09569b12;
	--dark-cyan: #0f86a1;
	--peach-puff: #ebc7b5;
	--linen: #f8efe5;

	--body-font-family: Montserrat, sans-serif;
	--body-font-color: #131313;
}

.w-layout-grid {
	grid-row-gap: 16px;
	grid-column-gap: 16px;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

body {
	color: #333;
	background-color: #f8efe5;
	font-family: stratos-lights;
	font-size: 18px;
	line-height: 20px;
}

h1 {
	letter-spacing: -0.02em;
	margin-top: 0;
	margin-bottom: 2rem;
	font-family: filson-pro;
	font-size: 78px;
	font-weight: 300;
	line-height: 1em;
}

h2 {
	margin-top: 0;
	margin-top: 10px;
	margin-bottom: 10px;
	font-family: filson-pro;
	font-size: 54px;
	font-weight: 400;
	line-height: 1em;
}

h3 {
	letter-spacing: -0.01em;
	margin-top: 16px;
	margin-bottom: 0;
	font-family: filson-pro;
	font-size: 24px;
	font-weight: 500;
	line-height: 30px;
}

h4 {
	margin-top: 16px;
	margin-bottom: 0;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
}

h5 {
	margin-top: 16px;
	margin-bottom: 0;
}

h6 {
	margin-top: 16px;
	margin-bottom: 0;
}

p {
	margin-bottom: 0;
	line-height: 1.4em;
}

a {
	text-decoration: underline;
	transition: all 0.2s;
}

a:hover {
	text-decoration: none;
}

ul {
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	list-style-type: none;
}

ul li ul {
	margin-top: 10px;
	padding-left: 15px;
}

ol li ol {
	margin-top: 10px;
}

.content-container {
	z-index: 2;
	flex-direction: column;
	align-self: center;
	align-items: center;
	width: 100%;
	display: flex;
	position: relative;
}

.content-container.provider {
	background-color: var(--delta-blue);
	border-radius: 20px;
	flex-flow: row;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	padding: 64px 100px;
}

.section {
	border-radius: 35px;
	padding: 100px 60px;
	position: relative;
}

.section.mod--courses {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	padding-top: 100px;
	padding-bottom: 140px;
	display: flex;
}

.section.mod--steps {
	z-index: 1;
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	border-bottom-right-radius: 50px;
	border-bottom-left-radius: 50px;
	justify-content: center;
	align-items: flex-start;
	height: 100%;
	padding-top: 140px;
	padding-bottom: 10px;
	display: flex;
	position: static;
}

.section.mod--steps.blue {
	z-index: 15;
	border-radius: 30px;
	justify-content: center;
	align-items: flex-start;
	margin-left: 60px;
	margin-right: 60px;
	padding-bottom: 64px;
	position: relative;
}

.section.mod--reviews {
	z-index: 1;
	background-color: #fff;
	background-image: none;
	background-size: auto;
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	margin-left: 60px;
	margin-right: 60px;
	padding: 60px 60px 60px 100px;
	display: flex;
}

.section.mod--reviews.vertical {
	flex-flow: column;
	padding-top: 0;
}

.section.variation {
	padding-top: 40px;
}

.section.mod--reviews-copy {
	z-index: 1;
	background-color: #fff;
	background-image: none;
	background-size: auto;
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 1350px;
	height: 100%;
	margin-left: 60px;
	margin-right: 60px;
	padding: 40px 60px 40px 100px;
	display: flex;
}

.home__content {
	z-index: 105;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	color: var(--white);
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	max-width: 700px;
	height: 100%;
	margin-left: 0;
	margin-right: auto;
	display: flex;
	position: relative;
}

.home__content.hero {
	margin-left: 0;
	margin-right: auto;
}

.btn {
	background-color: #c25400;
	color: var(--white) !important;
	text-align: center;
	text-transform: capitalize;
	border-radius: 100px;
	justify-content: center;
	align-self: flex-start;
	align-items: center;
	min-width: 175px;
	padding: 10px 6px;
	font-family: Open Sans, sans-serif;
	font-size: 16px;
	font-weight: 600;
	text-decoration: none;
	transition-duration: 0.5s;
	display: inline-block;
	position: relative;
	overflow: hidden;
	text-decoration: none !important;
	border-bottom: 1px solid transparent !important;
}

.btn:hover {
	background-color: var(--delta-blue);
}

.btn.mod--hero {
	background-color: var(--delta-blue);
	outline-color: var(--delta-orange);
	outline-offset: -2px;
	outline-width: 2px;
	outline-style: solid;
	min-width: 195px;
	max-width: 255px;
	text-decoration: none;
	transition-property: none;
	overflow: hidden;
}

.btn.alt {
	min-width: auto;
	padding-left: 21px;
	padding-right: 21px;
	font-family: Open Sans, sans-serif;
}

.btn.reverce {
	border: 2px solid var(--delta-orange);
	min-width: 150px;
}

.btn.reverce:hover {
	border-color: var(--white);
}

.subheading {
	opacity: 0.7;
	font-size: 22px;
}

.subheading.mod--hero {
	opacity: 1;
	color: var(--black);
	max-width: 600px;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 16px;
}

.subheading.mod--hero-copy {
	opacity: 1;
	color: var(--white);
	max-width: 600px;
	margin-bottom: 10px;
	font-size: 16px;
}

.courses__block {
	z-index: 2;
	cursor: pointer;
	background-color: #f8efe5;
	border-radius: 40px;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	height: 100%;
	min-height: 500px;
	padding: 40px 32px 56px;
	display: flex;
	position: relative;
	overflow: hidden;
}

.swiper-wrapper {
	z-index: 111;
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: 95px;
	display: flex;
	position: absolute;
	top: 550px;
	left: 0;
	right: auto;
}

.footer__drop {
	z-index: 0;
	margin-right: -40px;
}

.header__humb,
.hero__btn-wrap {
	display: none;
}

.btn__bg {
	background-color: var(--delta-orange);
	border-radius: 50%;
	flex: none;
	justify-content: center;
	align-items: center;
	width: 280px;
	max-width: 280px;
	height: 280px;
	position: relative;
}

.btn__txt {
	z-index: 2;
	color: var(--white);
	text-align: center;
	justify-content: center;
	align-items: center;
	font-family: Ubuntu, Helvetica, sans-serif;
	font-size: 16px;
	display: flex;
	position: relative;
}

.btn__bg-wrap {
	z-index: 0;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	display: flex;
	position: absolute;
	inset: 0%;
	overflow: hidden;
}

.section-slide {
	height: 100vh;
	padding-left: 48px;
	padding-right: 48px;
	position: absolute;
	inset: 0%;
	overflow: hidden;
}

.section-slide.mod--3 {
	z-index: 3;
	max-width: 1450px;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	position: static;
	overflow: visible;
}

.section-slide.mod--2 {
	z-index: 2;
	max-width: 1450px;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	position: static;
}

.section-slide.mod--1 {
	z-index: 1;
	justify-content: center;
	align-items: center;
	max-width: 1450px;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 60px;
	padding-left: 60px;
	padding-right: 60px;
	display: none;
	position: static;
	overflow: hidden;
}

.section-slide.footer-container {
	background-color: var(--3rd-bg);
	height: auto;
	padding-left: 0;
	padding-right: 0;
	position: static;
}

.section-slide.white {
	z-index: 1;
	background-color: var(--white);
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	margin-left: 0;
	margin-right: 0;
	padding: 40px 60px 20px;
	display: flex;
	position: static;
	overflow: hidden;
}

.body {
	background-color: var(--main-bg);
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	background-attachment: scroll;
	font-family: var( --body-font-family );
}

.form-2 {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	flex-flow: wrap;
	justify-content: flex-start;
	align-items: center;
	display: flex;
}

.field {
	background-color: var(--white);
	color: var(--delta-blue);
	border-radius: 100px;
	height: 100%;
	margin-bottom: 0;
	padding-top: 10px;
	padding-bottom: 10px;
}

.uui-navbar02_dropdown-right-overlay-absolute {
	background-color: #f9fafb;
	min-width: 100vw;
	position: absolute;
	inset: 0% auto 0% 0%;
}

.uui-navbar02_dropdown-content-wrapper {
	z-index: 1;
	grid-template-rows: max-content;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	display: grid;
	position: relative;
}

.uui-navbar02_blog-item {
	border-radius: 0.5rem;
	flex-direction: column;
	grid-template-rows: auto;
	grid-template-columns: max-content;
	grid-auto-columns: 1fr;
	padding: 0.75rem;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar02_blog-item:hover {
	background-color: #fff;
}

.uui-button-row {
	grid-column-gap: 0.75rem;
	grid-row-gap: 0.75rem;
	flex-wrap: wrap;
	align-items: center;
	display: flex;
}

.uui-button-link {
	grid-column-gap: 0.5rem;
	color: #6941c6;
	white-space: nowrap;
	background-color: #0000;
	justify-content: center;
	align-items: center;
	padding: 0;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-button-link:hover {
	color: #53389e;
}

.uui-button-link.is-button-xsmall {
	font-size: 0.875rem;
}

.uui-navbar02_dropdown-content-2 {
	width: 100%;
	display: flex;
}

.wrapper {
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	max-width: 1350px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 60px;
	display: flex;
}

.uui-navbar02_blog-image-wrapper {
	border-radius: 0.375rem;
	width: 100%;
	margin-bottom: 1.5rem;
	padding-top: 55%;
	position: relative;
	overflow: hidden;
}

.uui-navbar02_item-right {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	font-weight: 400;
	display: flex;
}

.uui-navbar02_blog-image {
	object-fit: cover;
	width: 100%;
	height: 100%;
	position: absolute;
	inset: 0%;
}

.uui-button-link-gray-2 {
	grid-column-gap: 0.5rem;
	color: #475467;
	white-space: nowrap;
	background-color: #0000;
	justify-content: flex-start;
	align-items: center;
	padding: 0;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-button-link-gray-2:hover {
	color: #344054;
}

.uui-button-link-gray-2.is-button-xsmall {
	font-size: 0.875rem;
}

.uui-text-size-small {
	color: #475467;
	letter-spacing: normal;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	font-size: 0.875rem;
	line-height: 1.5;
}

.uui-navbar02_dropdown-content-button-wrapper {
	letter-spacing: normal;
	margin-top: 0.75rem;
	font-size: 0.875rem;
}

.uui-navbar02_dropdown-blog-item-wrapper {
	flex-direction: column;
	grid-template-rows: auto auto auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	align-items: flex-start;
}

.uui-navbar02_blog-content {
	flex-direction: column;
	justify-content: flex-start;
	font-weight: 400;
	display: flex;
}

.uui-navbar02_dropdown-content-right-2 {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	flex: 1;
	grid-template-rows: max-content auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	max-width: 22rem;
	padding: 1.25rem;
	display: none;
	position: relative;
}

.exit-cb-wrapper {
	display: none;
}

.search-cb-wrapper {
	display: block;
}

.search-button-icon {
	z-index: 2;
	border: 1px none var(--delta-blue);
	background-color: #0000;
	background-image: url("/20181016082423/assets/images/search-icon-white.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
	border-radius: 20px;
	width: 30px;
	height: 30px;
	position: relative;
	top: 2px;
}

.search-button-icon:hover {
	background-color: #0000;
	background-image: url("/20181016082423/assets/images/search-icon-white.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
}

.search-button-icon:active {
	background-color: #0000;
	background-image: url("/20181016082423/assets/images/search-icon-white-01.png");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
}

.search-input-3 {
	opacity: 1;
	letter-spacing: 0.5px;
	background-color: #0000;
	border: 1px #000;
	margin-bottom: 0;
	padding-left: 0;
	font-size: 0.9rem;
}

.search-icon {
	background-color: var(--delta-blue);
	vertical-align: baseline;
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	margin-left: 16px;
	display: flex;
	overflow: visible;
}

.search-button-2 {
	color: var(--black);
	letter-spacing: 1px;
	text-transform: uppercase;
	background-color: #0000;
	border: 2px #b08d57;
	border-radius: 100px;
	padding: 0;
	font-size: 0.9rem;
	line-height: 1rem;
	transition: color 0.2s;
}

.search-button-2:hover {
	color: var(--delta-orange);
	background-color: #0000;
	border-style: none;
}

.search-3 {
	flex-direction: row;
	align-items: center;
	width: 100%;
	margin-bottom: 0;
	display: flex;
}

.exit-button-icon {
	z-index: 2;
	background-color: #0000;
	background-image: url("/20181016082423/assets/images/exit-icon-white.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
	border: 1px #233e99;
	border-radius: 20px;
	width: 10px;
	height: 30px;
	margin-top: 0;
	position: relative;
	inset: 0%;
	top: 2px;
}

.exit-button-icon:hover {
	background-color: #0000;
	background-image: url("/20181016082423/assets/images/exit-icon-white.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
}

.exit-button-icon:active {
	background-color: #0000;
	background-image: url("/20181016082423/assets/images/search-icon-white-01.png");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
}

.search-content {
	z-index: 0;
	opacity: 1;
	background-color: #fff;
	border-top: 1px #5b5b5b26;
	border-radius: 0 0 20px 20px;
	justify-content: space-around;
	align-self: center;
	align-items: center;
	width: 45%;
	height: 80px;
	margin-bottom: 0;
	margin-left: auto;
	padding: 10px 2rem 0;
	display: flex;
	position: absolute;
	right: 52px;
	top: 100% !important;
	box-shadow: 4px 12px 15px -7px #0000001a;
}

.dropdown-nav-list {
	color: var(--delta-blue);
	background-color: #e5ecf4;
	font-size: 16px;
}

.dropdown-nav-list.w--open {
	border: 1px solid var(--delta-blue);
	background-color: #e5ecf4;
	border-radius: 10px;
	min-width: 155px;
	padding-bottom: 0;
	padding-left: 0;
	padding-right: 0;
	left: -10px;
	right: auto;
	overflow: hidden;
}

.dropdown-nav-list.custom-scrollbar {
	border-radius: 0;
}

.heading {
	color: var(--white);
	width: 100%;
	max-width: none;
	margin-bottom: 0;
	font-family: var( --body-font-family );
	font-size: 52px;
	font-weight: 700;
}

.heading.hero {
	color: var(--delta-blue);
	font-family: var( --body-font-family );
	font-size: 46px;
}

.location-slide {
	margin-left: 30px;
	margin-right: 30px;
}

.slider-arrow {
	background-color: #fff;
	border: 1px solid #efeff7;
	border-radius: 100px;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	margin-left: -72px;
	display: flex;
	inset: auto auto -30px;
}

.location-card-wrapper {
	color: #fff;
	background-color: #fff;
	border-radius: 20px;
	flex-direction: column;
	grid-template-rows: 1fr auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	justify-content: center;
	align-items: stretch;
	width: 100%;
	max-height: 500px;
	padding: 16px;
	text-decoration: none;
	display: flex;
	position: relative;
	overflow: hidden;
}

.tabs_menu-service {
	border-bottom: 1px #a5a5a5;
	grid-template-rows: auto;
	grid-template-columns: max-content max-content max-content max-content max-content;
	grid-auto-columns: 1fr;
	justify-content: center;
	place-items: center start;
	margin-top: 1rem;
	margin-bottom: 2rem;
	display: grid;
}

.services-tabs_image {
	object-fit: cover;
	border-radius: 1rem;
	height: 21rem;
	margin-left: auto;
	margin-right: auto;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	width: 100%;
}

.margin-bottom {
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
}

.max-width-full {
	width: 100%;
	max-width: none;
}

.margin-top {
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
}

.margin-top.margin-medium {
	margin-top: 16px;
}

.tabs_content {
	color: #000;
	background-color: #fff;
	border-radius: 20px;
	padding: 1rem 1rem 1rem 3rem;
}

.tabs-layout_component {
	grid-column-gap: 2rem;
	grid-row-gap: 2rem;
	border-top-right-radius: 0;
	grid-template-rows: auto;
	grid-template-columns: 1fr 0.8fr;
	grid-auto-columns: 1fr;
	align-items: center;
	display: grid;
}

.text-size-medium {
	margin-bottom: 20px;
	font-size: 1rem;
}

.tabs_component--service {
	border-radius: 2rem 2rem 1.25rem 1.25rem;
	margin-left: 60px;
	margin-right: 60px;
}

.tabs-layout_image-wrapper {
	border-radius: 1rem;
}

.tabs1_link-service {
	color: #09569b;
	text-align: center;
	background-color: #fffc;
	border-top: 0.125rem solid #0000;
	border-radius: 10px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 175px;
	height: 100%;
	margin-left: 8px;
	margin-right: 8px;
	padding: 1.05rem 2.5rem;
	font-size: 1.25rem;
	font-weight: 600;
	transition: all 0.2s;
	display: flex;
	opacity: 0;
}

.tabs1_link-service:hover {
	background-color: #fff;
}

.w-tab-link.w--current {
	background-color: #fff;
}

button.tabs1_link-service.w--current {
	background-color: var(--delta-blue);
	color: #fff;
}

.tabs1_link-service.horizontal {
	background-color: var(--white);
	cursor: pointer;
	flex-direction: row;
	max-width: 210px;
	height: 100px;
	padding-left: 16px;
	padding-right: 16px;
	box-shadow: 0 2px 19px 3px #0f3d7045;
}

.tabs1_link-service.horizontal:hover {
	background-color: var(--delta-orange);
	color: var(--white);
	transform: translate(0, -5px);
}

.tabs_content-wrapper-service {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: flex;
}

.heading-2 {
	color: #09569b;
	background-image: none;
	font-family: var( --body-font-family );
	font-size: 35px;
	font-weight: 700;
	line-height: 41px;
	margin-bottom: 16px;
}

.heading-3 {
	text-align: left;
	margin-bottom: 0;
	padding-bottom: 0;
	font-family: var( --body-font-family );
	font-size: 52px;
	font-weight: 700;
}

.headingwrap {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	color: #fff;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
	display: flex;
}

.headingwrap.blue {
	grid-column-gap: 16px;
	grid-row-gap: 0px;
	color: var(--delta-blue);
	flex-direction: column;
	justify-content: center;
	margin-bottom: 48px;
}

.headingwrap.blue.alternative {
	margin-top: 40px;
	margin-bottom: 40px;
}

.accent {
	background-color: #fff;
	border-radius: 20px;
	width: 100px;
	height: 5px;
}

.accent.blue {
	background-color: #d45d0099;
	width: 25%;
	height: 3px;
}

.accent.orange {
	background-color: var(--delta-orange);
	width: 100px;
	height: 5px;
	margin-top: 10px;
}

.footer-text {
	color: #09569b;
	margin-bottom: 13px;
	font-size: 16px;
}

.border-line {
	background-color: #1e2037;
	width: 100%;
	height: 2px;
	position: absolute;
	inset: auto 0% 0;
	transform: translate(-101%);
}

.footer-nav-wrapper {
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	display: flex;
}

.footer-nav-wrapper._3 {
	justify-content: flex-start;
	width: 180px;
	max-width: 200px;
	padding-top: 128px;
}

.footer-nav-wrapper._1f {
	justify-content: flex-start;
	width: 180px;
	max-width: 200px;
	padding-top: 82px;
}

.footer-container {
	z-index: 15;
	border-style: solid none none;
	border-width: 1px;
	border-color: var(--main-bg) black black;
	color: #878994;
	background-color: #fff;
	width: 100%;
	padding-left: 60px;
	padding-right: 60px;
	font-size: 18px;
	line-height: 21px;
	position: relative;
}

.footer-column.footer1 {
	align-items: center;
	margin-right: 20px;
	display: flex;
}

.social-wrapper {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	flex-flow: column;
	grid-template-rows: auto;
	grid-template-columns: auto auto 1fr;
	grid-auto-columns: 1fr;
	display: flex;
}

.footer-nav-link {
	color: #09569b;
	margin-bottom: 16px;
	font-size: 16px;
	text-decoration: none;
	transition: color 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
	display: inline-block;
	position: relative;
	overflow: hidden;
}

.footer-nav-link:hover {
	color: var(--delta-orange);
}

.footer-nav-link._2 {
	font-size: 16px;
}

.footer-nav-link._1 {
	margin-bottom: 11px;
	padding-bottom: 0;
	font-size: 16px;
	line-height: 21px;
	display: inline-block;
}

.footer-nav-link._3,
.footer-nav-link._4 {
	padding-bottom: 0;
	font-size: 16px;
}

.footer-nav-link._4-copy {
	font-size: 16px;
}

.footer-text-contact {
	color: #09569b;
	margin-bottom: 33px;
	font-size: 16px;
}

.footer-brand {
	margin-bottom: 0;
}

.social-link {
	background-color: var(--delta-blue);
	border-radius: 100%;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	display: flex;
}

.social-link:hover {
	background-color: var(--delta-orange);
}

.grip-logo-image {
	width: 9rem;
	min-width: 150px;
	transition: all 0.45s;
}

.sign-off-div-wrap {
	width: 100%;
	min-width: 991px;
}

.column-2 {
	justify-content: flex-end;
	padding-right: 0;
	display: flex;
}

.web-development-by {
	color: #fff;
	letter-spacing: 0.045rem;
	font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
	font-size: 0.65rem;
	line-height: 0.75rem;
	position: absolute;
	inset: 0% 0% auto auto;
}

.footer-signoff-link-wrap {
	font-size: 16px;
	display: inline-block;
}

.footer-signoff-links {
	opacity: 0.66;
	border-bottom: 2px solid #fff;
	margin-right: 1rem;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.25rem;
	display: inline-block;
}

.footer-signoff-links:hover {
	opacity: 1;
}

.grip-logo-link {
	float: right;
	opacity: 0.5;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, opacity 0.2s;
}

.grip-logo-link:hover {
	opacity: 1;
}

.footer-signoff-link {
	color: #fff;
	text-decoration: none;
	transition: color 0.3s;
	border-bottom: 1px solid transparent;
}

.column-3 {
	align-items: center;
	padding-left: 0;
	padding-right: 0;
	font-size: 14px;
	display: flex;
}

.footer-copyright-2 {
	color: #fff;
	margin-bottom: 0;
	margin-right: 1.5rem;
	font-size: 16px;
	display: inline-block;
}

.footer-copyright-2 h1 {
	margin: 0px;
	padding: 0px;
	display: inline;
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit;
}

.signoff-div {
	background-color: var(--delta-blue);
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 1.5rem 60px 4rem;
	display: flex;
}

.columns-2 {
	align-items: center;
	display: flex;
}

.textwithicon {
	width: 100px;
	font-family: var( --body-font-family );
	font-size: 1rem;
}

.iconwrap {
	width: 50px;
	max-width: 50%;
	height: 50px;
	margin-bottom: 5px;
}

.iconwrap.horizontal {
	align-self: center;
	height: 50px;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
}

.iconwrap.horizontal.small {
	height: 40px;
}

.html-embed {
	width: 100%;
	height: 100%;
}

.html-embed.alternative {
	justify-content: center;
	align-items: center;
	width: auto;
	display: flex;
}

.category-title {
	color: #d34500;
	letter-spacing: 1.5px;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 700;
}

.paragraph {
	color: #546681;
	max-width: 490px;
	font-size: 17px;
	font-style: normal;
	line-height: 1.3;
}

.blog-placeholder-image-03 {
	background-position: 50%;
	background-size: cover;
	border-radius: 14px;
	width: 100%;
	min-height: 220px;
}

.blog-article-wrap {
	flex-wrap: wrap;
	place-content: stretch space-between;
	align-items: stretch;
	display: flex;
}

.blog-content-wrap {
	flex-direction: column;
	align-items: flex-start;
	min-height: 140px;
	padding-top: 15px;
	padding-left: 4px;
	padding-right: 4px;
	display: flex;
}

.card {
	background-color: #fff;
	border-radius: 20px;
	width: 100%;
	margin-right: 0;
	padding: 15px 16px 35px;
	transition: all 0.3s ease-out;
	overflow: hidden;
	box-shadow: 14px 14px 40px #767ead14;
}

.card:hover {
	transform: translate(0, -4px);
	box-shadow: 14px 14px 40px #767ead1f;
}

.blog-placeholder-image {
	background-position: 50%;
	background-size: cover;
	border-radius: 14px;
	width: 100%;
	min-height: 220px;
}

.cardwrapper {
	width: 33%;
	display: flex;
	padding: 10px;
}

.blog-content {
	margin-bottom: 25px;
}

.heading-5 {
	color: #2e3642;
	text-align: left;
	margin-top: 0;
	margin-bottom: 8px;
	font-family: var( --body-font-family );
	font-size: 22px;
	font-weight: 700;
	line-height: 29px;
}

.blog-placeholder-image-02 {
	background-position: 50%;
	background-size: cover;
	border-radius: 14px;
	width: 100%;
	min-height: 220px;
}

.btnwrap {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 20px;
	display: flex;
}

.top-nav-div {
	z-index: 10000;
	background-color: #e5ecf4;
	border-bottom: 2px solid #09569b;
	justify-content: space-between;
	padding-top: 10px;
	padding-bottom: 10px;
	display: none;
	position: fixed;
	left: 0;
	right: 0;
}

.links {
	color: #222;
	cursor: pointer;
	font-size: 15px;
	font-weight: 400;
	text-decoration: none;
}

.links:hover {
	color: var(--delta-orange);
}

.quick-links {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	place-content: center;
	place-items: center;
	width: 100%;
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
	display: grid;
}

.naviconwrap {
	color: #09569b99;
	width: 25px;
	height: 20px;
	margin-left: 16px;
	margin-right: 5px;
}

.innertopnavwrap {
	justify-content: flex-end;
	width: 100%;
	max-width: 1450px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 60px;
	padding-right: 60px;
	display: flex;
}

.text-block-45 {
	font-size: 17px;
	line-height: 23px;
}

.dropdown-list {
	overflow: auto;
}

.dropdown-list.w--open {
	background-color: #fff0;
	border-radius: 10px;
	max-height: 300px;
	margin-top: 10px;
}

.dropdown-list.custom-scrollbar.w--open {
	z-index: 1500;
}

.dropdown-link-2 {
	color: var(--delta-blue);
}

.dropdown-link-2:hover {
	background-color: #d1e1f3;
}

.heroslider {
	height: 600px;
}

.slide {
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 80px 80px 80px 115px;
}

.sliderwrap {
	justify-content: flex-start;
	align-items: flex-end;
	width: 100%;
	height: 100%;
	display: flex;
}

.slide-nav-2 {
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 70px;
	padding-left: 115px;
	display: flex;
}

.form-block-4 {
	margin-bottom: 0;
}

.floatingimg {
	background-image: url("/20181016082423/assets/images/doctor.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	width: 100%;
	max-width: 250px;
	height: 40vh;
	margin-right: 32px;
}

.nav-underline-two {
	background-color: #e6674a;
	width: 0;
	height: 4px;
	margin-bottom: -1px;
}

.search-icon-3 {
	background-color: #0000;
	background-image: url("/20181016082423/assets/images/green-search-icon.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	width: auto;
	height: 22px;
	margin-right: 0.5rem;
	padding-left: 0;
}

.search-icon-3:hover {
	background-image: url("/20181016082423/assets/images/green-search-icon.svg");
}

.location-icon {
	width: 22px;
	max-width: none;
}

.search-input-4 {
	color: #4d4d4d;
	background-color: #0000;
	background-image: url("/20181016082423/assets/images/green-search-icon.svg");
	background-position: 98% 75%;
	background-repeat: no-repeat;
	background-size: 16px;
	border: 1px #000;
	border-bottom: 2px solid #d9dbe9a6;
	border-radius: 0;
	width: 700px;
	margin-bottom: 1rem;
	margin-left: auto;
	padding: 2rem 1.25rem 0.75rem 0.5rem;
	font-size: 0.85rem;
	font-weight: 400;
}

.nav-link-icon-wrapper {
	align-items: center;
	width: 20px;
	margin-top: 0;
	margin-bottom: 0.125rem;
	display: block;
}

.nav-link-icon-wrapper.locations-icon {
	width: 20px;
}

.nav-link-icon-wrapper.specialties-icon._2 {
	width: 21px;
}

.nav-icon {
	border-radius: 20px;
}

.main-nav-link {
	color: var(--black);
	align-items: center;
	height: 100%;
	margin-right: 0;
	padding: 0.25rem 0 0.25rem 0.4rem;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1rem;
	text-decoration: none;
	transition: color 0.25s;
	display: flex;
}

.main-nav-link:hover {
	color: #4d4d4f;
	border-top: 3px #e6674a;
}

.nav-link-2 {
	color: #000;
	cursor: pointer;
	padding: 0.5rem 1.25rem;
	font-size: 0.9rem;
	font-weight: 500;
	line-height: 1.2rem;
	text-decoration: none;
	transition: all 0.15s;
	display: block;
	position: relative;
}

.nav-link-2.w--current {
	color: #f9b604;
	background-color: #03346699;
}

.nav-link-2.mobile {
	display: none;
}

.nav-underline {
	background-color: #e6674a;
	width: 0;
	height: 4px;
	margin-bottom: -1px;
}

.nav-wrapper-2 {
	justify-content: space-between;
	align-items: flex-end;
	display: flex;
}

.two-part-mobile-nav-wrapper-2 {
	display: none;
}

.image-2 {
	width: 100px;
	margin-top: 2px;
	transition: transform 0.25s;
}

.right-top-header-links {
	justify-content: flex-start;
	display: none;
}

.logo-image {
	display: block;
}

.main-nav-link-wrapper {
	border-top: 4px #0000;
	border-bottom: 4px #0000;
	flex-flow: column wrap;
	justify-content: center;
	align-items: stretch;
	margin-right: 2.5vw;
	display: flex;
}

.main-nav-link-wrapper:hover {
	background-color: #0000;
	border: 1px #000;
}

.main-nav-link-wrapper.last {
	margin-right: 0;
}

.main-nav-link-wrapper.out {
	display: none;
}

.header-nav-wrapper {
	background-color: #fff;
	border-bottom: 1px solid #6e6e6e26;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;
	padding: 0 1.5rem;
	display: flex;
}

.mobile-header-link-wrapper {
	display: none;
}

.top-header-nav-wrapper {
	background-color: #fff;
	border: 1px #d8d8d8;
	flex-wrap: wrap;
	order: 0;
	justify-content: flex-end;
	align-items: stretch;
	padding: 8px 0;
	font-family: Lato, sans-serif;
	display: flex;
}

.search-bar-wrap-2 {
	color: #fff;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-bottom: 0;
	font-family: Lato, sans-serif;
	display: flex;
}

.left-top-header-links {
	display: flex;
}

.left-top-header-links.mobile-view-hidden {
	align-items: center;
	font-family: var( --body-font-family );
}

.search-wrapper-2 {
	opacity: 1;
	cursor: pointer;
	background-color: #0000;
	border-top: 4px solid #0000;
	border-left: 1px solid #ffffff26;
	flex: 0 auto;
	align-items: center;
	width: auto;
	height: auto;
	margin-left: 0;
	margin-right: 0;
	padding: 1rem 1rem 1rem 0.5rem;
	display: flex;
}

.navbar-wrapper-2 {
	z-index: 5;
	border-bottom: 4px #0000;
	flex-direction: column;
	display: flex;
	position: relative;
	box-shadow: 1px 1px 4px 2px #4d4d4d0d;
}

.right-side-nav-wrapper {
	flex-direction: column;
	align-items: flex-end;
	display: flex;
}

.search-input-exit-2 {
	background-color: #0000;
	background-image: url("/20181016082423/assets/images/exit-icon.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 12px 12px;
	width: 25px;
	margin-bottom: 0;
	margin-left: auto;
	margin-right: 1.5rem;
	padding-left: 0;
	padding-right: 0;
	display: block;
}

.search-input-exit-2.two {
	display: none;
}

.top-header-nav-link {
	opacity: 1;
	color: #313131;
	letter-spacing: 0;
	text-transform: none;
	border-top: 4px #0000;
	border-bottom: 1px #474747;
	border-right: 1px #d8d8d8;
	align-items: center;
	margin-left: 1rem;
	margin-right: 1rem;
	padding: 1rem 0;
	font-size: 1rem;
	font-weight: 600;
	line-height: 0.85rem;
	text-decoration: none;
	transition: transform 0.4s, opacity 0.3s, border-color 0.35s, color 0.35s;
	display: flex;
}

.top-header-nav-link:hover {
	opacity: 1;
	color: #222;
	border-top-style: none;
	border-top-color: #e6674a;
	border-bottom: 1px #fff;
	text-decoration: underline;
}

.top-header-nav-link.mychart {
	opacity: 1;
	color: #4d4d4d;
	letter-spacing: 0;
	border-bottom-style: none;
	border-right: 1px #d8d8d8;
	flex: 0 auto;
	min-width: 110px;
	margin-left: 0;
	margin-right: 0;
	padding: 1rem;
	font-size: 0.9rem;
	font-weight: 700;
	text-decoration: none;
}

.top-header-nav-link.mychart:hover {
	border-top-color: #0000;
}

.top-header-nav-link.schedule-appt-button {
	background-color: #c25400;
	opacity: 1;
	color: #fff;
	white-space: nowrap;
	background-image: url("/20181016082423/assets/images/schedule-an-appt-white-icon.svg");
	background-position: 6%;
	background-repeat: no-repeat;
	background-size: 20px;
	border-radius: 20px;
	height: auto;
	margin-left: 0;
	margin-right: 0;
	padding: 0.85rem 1rem 0.85rem 2.65rem;
	font-family: var( --body-font-family );
	font-weight: 500;
	transition: all 0.3s, background-color 0.35s;
}

.top-header-nav-link.schedule-appt-button:hover {
	background-color: var(--delta-blue);
	transform: none;
	box-shadow: 2px 2px 8px #0000004d;
}

.top-header-nav-link.schedule-appt-button.mobileversion {
	display: none;
}

.top-header-nav-link.last {
	border-right-style: none;
	align-items: center;
	margin-right: 2rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-right: 0;
	display: flex;
}

.top-header-nav-link.alt:hover {
	text-decoration: none;
}

.search-text {
	opacity: 1;
	color: #4d4d4d;
	font-family: Lato, sans-serif;
	font-size: 0.95rem;
	font-weight: 700;
}

.search-text:hover {
	opacity: 1;
}

.nav-logo {
	width: 400px;
	min-width: auto;
	max-width: 400px;
	margin-top: 0;
	margin-right: 2rem;
}

.search-bar-2 {
	z-index: 100;
	background-color: #f2f7fa;
	justify-content: center;
	align-items: center;
	display: none;
	position: relative;
	inset: 0 0% auto;
}

.nav-underline-three {
	background-color: #e6674a;
	width: 0;
	height: 4px;
	margin-bottom: -1px;
}

.main-header-wrapper {
	padding-left: 0;
	padding-right: 0;
}

.navitems {
	border-top: 1px #d8d8d880;
	flex-flow: row;
	order: 0;
	justify-content: flex-end;
	align-self: stretch;
	align-items: center;
	margin-left: 0;
	padding-top: 8px;
	padding-bottom: 16px;
	display: flex;
}

.navbar-2 {
	position: sticky;
	top: 0;
}

.bold-text:hover {
	text-decoration: underline;
}

.text-span-2 {
	font-weight: 600;
	text-decoration: none;
}

.text-span-2:hover {
	text-decoration: none;
}

.map-wrapper {
	background-image: url("/20181016082423/assets/images/Map-Location-File-2024.png");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: contain;
	border-radius: 5px;
	width: 70%;
	height: 125%;
}

.heading-6 {
	color: #0081a0;
	text-align: left;
	max-width: 400px;
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-family: Lato, sans-serif;
	font-size: 2.75rem;
	font-weight: 400;
	line-height: 3rem;
}

.heading-wrapper {
	background-color: #0000;
	flex-direction: column;
	align-items: flex-start;
	width: 50%;
	height: auto;
	max-height: none;
	margin-top: 0;
	padding-top: 2rem;
	padding-left: 0;
	padding-right: 2rem;
	display: flex;
}

.heading-wrapper.our-locations {
	color: var(--delta-blue);
	justify-content: flex-start;
	align-items: flex-start;
}

.text-block {
	color: #4d4d4f;
	max-width: 450px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-family: Open Sans, sans-serif;
	font-size: 1.15rem;
	font-weight: 400;
	line-height: 1.4rem;
}

.locations-section {
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 447.953px;
	display: flex;
	position: relative;
}

.mask {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	overflow: hidden;
}

.clinics-slider h4 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	color: #333;
}

.clinics-slider a:hover h4 {
	color: #fff;
}

.slide-2 {
	width: 25%;
	padding-left: 8px;
	padding-right: 8px;
}

.slide-nav-3 {
	display: none;
}

.content-2 {
	z-index: 2;
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	flex-flow: column;
	width: 100%;
	max-width: 1350px;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	position: relative;
}

.clinic-block {
	box-shadow: none;
	background-color: #d8d2bc;
	border-radius: 8px;
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: 100%;
	padding: 40px;
	transition: all 0.3s;
	display: flex;
}

.clinic-block:hover {
	background-color: #fff;
	transform: translate(0, -20px);
	box-shadow: 0 100px 80px -60px #090d1c0f;
}

.clinic-block.locations {
	background-color: var(--secondary-bg);
	cursor: pointer;
	padding: 16px;
}

.clinic-block.locations:hover {
	color: var(--white);
	background-color: #09569b;
	transform: translate(0, -10px);
}

.clinics-slider .image-wrapper {
	z-index: 1;
	background-color: #fff;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 3px;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 200px;
	margin-bottom: 16px;
	display: flex;
	position: relative;
	overflow: hidden;
	box-shadow: 0 10px 30px #005a8d0d;
}

.clinics-slider a {
	flex: 1;
	text-decoration: none !important;
	color: #333;
	border-bottom: 1px solid transparent !important;
}

.slider {
	background-color: #ddd0;
	height: 450px;
	margin-top: -32px;
}

.arrow {
	background-color: #d8d2bc;
	background-image: url("/20181016082423/assets/images/arrow-narrow-right-svgrepo-com.svg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 20px;
	border-radius: 50px;
	align-self: center;
	width: 50px;
	height: 50px;
}

.arrow:hover {
	background-color: #b3aa7e;
}

.arrow._2 {
	background-color: #09569b12;
	background-image: url("/20181016082423/assets/images/arrow-right24x242x-1.svg");
}

.arrow._2:hover {
	background-color: #09569b7a;
}

.arrow._2.left {
	transform: rotate(-180deg);
	left: -65px;
}

.arrow._2.w-slider-arrow-right {
	right: -65px;
}

.content-2 .w-slider-mask {
	overflow: visible;
}

.paragraph-2,
.slider-text {
	margin-top: 10px;
	font-size: 16px;
}

.clinics-slider .slider-text {
	text-align: left;
}

.paragraph-2.link-copy,
.slider-text.link-copy {
	color: var(--delta-orange);
	display: none;
}

.paragraph-2.link-copy:hover,
.slider-text.link-copy:hover {
	color: var(--delta-orange);
	text-decoration: none;
}

.image-3,
.image-4 {
	width: 100%;
}

.image-5 {
	width: 100%;
	height: 100%;
}

.herotextcon {
	background-color: #fff;
	padding: 30px;
}

.footerinfo {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	justify-content: space-between;
	max-width: 1350px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 0;
	padding-bottom: 40px;
	display: flex;
}

.footer-link-label-new {
	color: #09569b;
	margin-top: 10px;
	margin-bottom: 10px;
	font-family: var( --body-font-family );
	font-size: 18px;
	font-weight: 700;
	line-height: 26px;
	text-decoration: none;
}

.paragraph-2-copy {
	margin-top: 10px;
	font-size: 16px;
}

.uui-navbar02_item-heading-2-copy-8 {
	color: #0b2239;
	letter-spacing: 1px;
	-webkit-text-stroke-color: white;
	text-transform: capitalize;
	margin-bottom: 0.25rem;
	font-size: 16px;
	font-weight: 600;
}

.uui-navbar02_item-heading-2-copy-8:hover {
	color: var(--delta-orange);
}

.text-block-11 {
	color: #4d4d4d;
	font-family: Open Sans, sans-serif;
	font-size: 1.1rem;
	font-weight: 700;
}

.text-block-11:hover {
	color: #4d4d4d;
}

.uui-dropdown-icon-4 {
	color: #4d4d4d;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	padding-top: 5px;
	font-weight: 800;
	display: flex;
}

.uui-dropdown-icon-4:hover {
	color: #4d4d4d;
	padding-top: 5px;
}

.uui-navbar02_dropdown-link-list-2 {
	grid-column-gap: 0px;
	grid-row-gap: 0rem;
	flex-direction: column;
	grid-template-rows: max-content;
	grid-template-columns: 1fr;
	grid-auto-rows: max-content;
	grid-auto-columns: 1fr;
	display: flex;
}

.uui-navbar02_dropdown-toggle-3 {
	color: #475467;
	justify-content: flex-start;
	align-items: center;
	height: auto;
	padding: 0;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar02_dropdown-toggle-3:hover {
	color: #344054;
}

.uui-navbar02_dropdown-toggle-3.w--open {
	height: auto;
	padding-bottom: 0;
}

.uui-navbar02_dropdown-toggle-3.w--open:hover {
	padding-bottom: 0;
}

.dropdown-link-3 {
	grid-column-gap: 0rem;
	grid-row-gap: 0px;
	color: #1a1a1a;
	border-radius: 0.5rem;
	grid-template-rows: auto;
	grid-template-columns: max-content 1fr;
	grid-auto-columns: 1fr;
	justify-content: flex-start;
	align-items: center;
	padding-top: 0.35rem;
	padding-bottom: 0.35rem;
	padding-left: 0.75rem;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.dropdown-link-3:hover {
	background-color: #0000;
}

.nav_link {
	color: #0b2239;
	padding: 1rem;
	font-size: 20px;
	font-weight: 400;
	transition: color 0.2s;
}

.nav_link:hover,
.nav_link.w--current {
	color: #f58a07;
}

.nav_link.out {
	padding-left: 0;
	padding-right: 2.5vw;
	display: none;
}

.nav_link.out:hover {
	color: #84a63d;
}

.uui-navbar02_dropdown-list-3 {
	box-shadow: 0 12px 16px -4px #00000014, 0 4px 6px -2px #10182808;
}

.uui-navbar02_dropdown-list-3.w--open {
	background-color: #fff;
	width: 230px;
	min-width: auto;
	max-width: none;
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	padding-top: 0;
	padding-left: 0%;
	padding-right: 0;
	position: absolute;
	inset: 94% auto auto;
	overflow: hidden;
}

.uui-navbar02_container-3 {
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: none;
	height: auto;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.ui-nav-bar-container-2 {
	background-color: #fff;
	margin-top: 0;
}

.uui-navbar02_heading-3 {
	color: #1a1a1a;
	letter-spacing: normal;
	text-transform: capitalize;
	cursor: pointer;
	margin-top: 0;
	margin-bottom: 0.25rem;
	padding-left: 0.75rem;
	display: none;
}

.uui-navbar02_dropdown-content-left-3 {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	flex: 1;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	padding: 0.5rem 2rem 0 0;
	display: grid;
}

.uui-navbar02_item-heading-3 {
	color: #fff;
	letter-spacing: 1px;
	-webkit-text-stroke-color: white;
	text-transform: uppercase;
	margin-bottom: 0.25rem;
	font-weight: 400;
}

.uui-navbar02_item-heading-3:hover {
	color: #faa831;
}

.footer-link-label-new-copy {
	color: #09569b;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 22px;
	font-weight: 700;
	line-height: 26px;
	text-decoration: none;
	display: none;
}

.footer-text-copy {
	color: #09569b;
	margin-bottom: 13px;
	font-size: 16px;
	display: none;
}

.footer-text-contact-copy {
	color: #09569b;
	margin-bottom: 33px;
	font-size: 16px;
	display: none;
}

.div-block-131 {
	grid-column-gap: 16px;
	grid-row-gap: 0px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.footer-link-label-new-follow {
	color: #09569b;
	margin-top: 0;
	margin-bottom: 0;
	font-family: var( --body-font-family );
	font-size: 18px;
	font-weight: 700;
	line-height: 26px;
	text-decoration: none;
}

.icon-10 {
	color: #3a3a3c;
	margin-right: 5px;
	font-size: 14px;
	position: relative;
}

.text-block-3 {
	color: var(--black);
	letter-spacing: 0;
	text-transform: capitalize;
	margin-right: 8px;
	font-size: 1rem;
	font-weight: 600;
}

.text-block-3:hover {
	text-decoration: none;
}

.navigation-dropdown-copy {
	min-width: 140px;
}

.navigation-dropdown-copy.w--open {
	background-color: #ddd0;
	max-height: 100vh;
	padding-top: 36px;
	left: auto;
}

.text-block-61 {
	text-transform: capitalize;
	font-size: 16px;
}

.button-link {
	color: #fff;
	letter-spacing: 4px;
	text-transform: uppercase;
	white-space: nowrap;
	background-color: #0000;
	border-bottom: 1px solid #ffffff1a;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	font-size: 11px;
	text-decoration: none;
	position: relative;
}

.button-link.w--current {
	color: #9e99fa;
	background-color: #0000;
}

.button-link.nav {
	border-bottom: 0 #fff0;
	justify-content: center;
	align-items: center;
	padding: 0 0;
	display: flex;
}

.button-link.nav:hover {
	text-decoration: none;
}

.text-block-62 {
	white-space: nowrap;
}

.dropdown-2 {
	margin-right: 0;
	padding-right: 2.5vw;
	display: flex;
}

.dropdown-link-1 {
	color: #222;
	border-radius: 5px;
	width: 100%;
	padding: 16px;
	font-size: 16px;
	text-decoration: none;
	display: flex;
}

.dropdown-link-1:hover {
	background-color: #0061a033;
	border-radius: 5px;
	text-decoration: none;
}

.nav-content-wrap {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	display: flex;
}

.navigation-dropdown {
	background-color: var(--white);
	white-space: nowrap;
	border-radius: 10px;
	width: 180px;
	min-width: 180px;
	max-width: 180px;
	padding: 6px;
	position: relative;
	overflow: visible;
	box-shadow: 0 2px 5px #0003;
}

.navigation-dropdown.w--open {
	background-color: #fff;
	min-width: 350px;
	max-height: 100vh;
	padding-top: 20px;
	left: -120px;
	box-shadow: 0 50px 25px -50px #20355a26, 8px 18px 60px 4px #00000021;
}

.location-icon-new {
	width: 22px;
	max-width: 18px;
	max-height: 20px;
	margin-right: 4px;
}

.breadcrumb-divider {
	float: right;
	color: var(--main-bg);
	margin-right: 0.25rem;
	font-size: 1rem;
	line-height: 1rem;
}

.left-side-secondary-content-box {
	border: 2px solid var(--delta-orange);
	color: #222;
	border-radius: 10px;
	margin-right: 24px;
	padding: 32px;
	font-size: 16px;
	transition: all 0.3s;
}

.left-side-secondary-content-box:hover {
	background-color: #09569b0d;
	border-radius: 35px;
}

.paragraph-12 {
	color: #333;
	line-height: 1.5rem;
}

.paragraph-16 {
	font-size: 16px;
	font-weight: 400;
}

.right-sidebar-column {
	max-width: 350px;
}

.h6-header-link {
	color: var(--delta-blue);
	text-transform: capitalize;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	transition: all 0.2s;
	display: inline-block;
}

.h5-header-link {
	color: var(--delta-blue);
	text-transform: capitalize;
	font-size: 18px;
	font-weight: 400;
	line-height: 20px;
	transition: all 0.2s;
	display: inline-block;
}

.h5-header-link:hover {
	background-color: #0000;
	border-bottom-color: #0000;
}

.h4-header-link {
	color: var(--delta-blue);
	font-size: 22px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.2s;
	display: inline-block;
}

.h4-header-link:hover {
	background-color: #0000;
	border-bottom-width: 2px;
	border-bottom-color: #0000;
	font-weight: 400;
}

.ordered-list-2 {
	margin-bottom: 1rem;
	font-family: Open Sans, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.intro-paragraph-2 {
	color: #333;
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.5rem;
}

.div-block-152-copy {
	background-color: var(--delta-blue);
	color: #fff;
	border-radius: 10px;
	width: 250px;
	margin-left: 1.5rem;
	padding: 32px;
	font-size: 16px;
	transition: all 0.3s;
}

.div-block-152-copy:hover {
	border-radius: 35px;
}

.breadcrumb-link-1 {
	color: #313131;
	text-transform: none;
	border-bottom: 0.5px solid #0000;
	line-height: 1rem;
	text-decoration: none;
	display: inline;
}

.breadcrumb-link-1.w--current {
	color: #28313b;
	font-weight: 700;
}

.inside-pages-paragraph {
	margin-top: 8px;
	padding-bottom: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.25rem;
	text-decoration: none;
}

.inside-pages-paragraph.list-item {
	color: #222;
	margin-bottom: 0;
	transform: none;
}

.link-8 {
	color: var(--delta-blue);
	font-weight: 400;
	text-decoration: underline;
}

.link-8:hover {
	text-decoration: none;
}

.link-8.w--current {
	color: #28313b;
	font-weight: 700;
}

.link-8.white {
	color: var(--white);
}

.breadcrumb-current {
	text-transform: capitalize;
	border-bottom: 0.5px solid #0000;
	font-weight: 700;
	line-height: 1rem;
	text-decoration: none;
	display: inline;
}

.breadcrumb-current:hover {
	line-height: 1rem;
}

.breadcrumb-current.w--current {
	color: #28313b;
	font-weight: 700;
}

.heading-7 {
	color: var(--delta-blue);
	background-color: #0000;
	width: 100%;
	margin-top: 0;
	margin-bottom: 2rem;
	padding: 0;
	font-family: var( --body-font-family );
	font-size: 2.75rem;
	font-weight: 800;
}

.unordered-list {
	margin-bottom: 1rem;
}

.inner-image-content {
	background-image: url("/20181016082423/assets/images/GettyImages-1180123651.jpg");
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: auto;
	height: 100%;
}

.inner-image-content.recent-blog-post {
	background-image: url("/20181016082423/assets/images/AdobeStock_155336645_Preview.jpeg");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 10px;
	align-items: flex-end;
	width: 100%;
	height: 100%;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	display: flex;
}

.block-quote-2 {
	background-image: url("/20181016082423/assets/images/missouri-20---opacity.svg"), linear-gradient(to bottom, var(--delta-blue), var(--delta-blue));
	color: #fff;
	background-position: 95%, 0 0;
	background-repeat: no-repeat, repeat;
	background-size: auto 80%, auto;
	border-radius: 10px;
	flex-flow: column;
	margin-top: 8px;
	margin-bottom: 8px;
	padding: 32px;
	font-size: 1.5rem;
	font-style: italic;
	font-weight: 400;
	line-height: 1.75rem;
	display: flex;
}

.table-div {
	color: var( --body-font-color );
	font-size: 0.8rem;
	font-weight: 400;
}

.breadcrumb-list {
	text-transform: uppercase;
	justify-content: flex-start;
	align-items: center;
	margin: 0 0.125rem;
	padding-left: 0;
	font-size: 0.6875rem;
	line-height: 1em;
	display: flex;
	list-style-type: none;
}

.breadcrumb-list-item {
	float: left;
	border: 2px solid var(--main-bg);
	border-radius: 30px;
	justify-content: center;
	align-items: flex-start;
	margin-right: 0.25rem;
	padding: 4px 10px;
	font-size: 0.9rem;
	display: flex;
}

.breadcrumb-list-item:hover {
	background-color: var(--secondary-bg);
}

.breadcrumb-list-item.current {
	background-color: var(--main-bg);
}

.sidebar-section-title-2 {
	color: var(--delta-blue);
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 27px;
}

.sidebar-section-title-2.white {
	color: var(--white);
}

.image-block {
	width: 75%;
	height: 375px;
	position: relative;
	overflow: hidden;
}

.image-block.inside-page {
	width: 100%;
	height: 300px;
	margin-bottom: 2rem;
}

.left-nav-list-item {
	box-shadow: none;
}

.main-content-wrapper {
	background-color: #fff;
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 64px;
	padding-left: 48px;
	padding-right: 48px;
	display: block;
	box-shadow: 0 2px 20px -7px #0003;
	min-height: 500px;
}

.main-content-wrapper.leftright {
	max-width: 1050px;
}

.main-content-wrapper.full {
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 1050px;
	padding-top: 32px;
	display: flex;
}

.sidebar-section-wrap {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	border-top: 1px #00000026;
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	line-height: 1.35rem;
	display: flex;
	position: relative;
}

.button-styling {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	align-items: center;
	margin-top: 16px;
	margin-bottom: 32px;
	padding-left: 0;
	font-family: Open Sans, sans-serif;
	font-weight: 800;
	display: flex;
}

.left-nav-list-content-wrap {
	background-color: var(--secondary-bg);
	color: #222;
	border-right: 1px #0000000d;
	border-radius: 10px;
	height: auto;
	margin-top: 0;
	margin-right: 2rem;
	padding: 8px;
	transition: all 0.3s;
	display: block;
	overflow: hidden;
}

.left-nav-list-content-wrap:hover {
	border-radius: 35px;
}

.inside-page-header-wrap {
	border-bottom: 0.5px solid #33333326;
	width: 100%;
	margin-bottom: 2rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.inside-page-header-wrap.full {
	max-width: 900px;
	padding-top: 0;
}

.left-nav-list-link {
	color: #222;
	text-transform: capitalize;
	border-radius: 25px;
	padding: 0.75rem 1.5rem 0.75rem 1rem;
	font-weight: 400;
	text-decoration: none;
	transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	display: block;
}

.left-nav-list-link:hover {
	background-color: var(--secondary-bg);
	color: #272727;
	text-decoration: none;
}

.inside-content {
	justify-content: space-between;
	align-items: stretch;
	display: flex;
}

.inside-content.full {
	justify-content: flex-start;
	align-items: stretch;
	max-width: 900px;
}

.left-nav-list {
	box-shadow: none;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
}

.left-nav-nested-list-item {
	box-shadow: none;
	margin-bottom: 0;
}

.dividingline {
	background-color: var(--main-bg);
	border-radius: 20px;
	width: 100%;
	height: 5px;
	margin-top: 16px;
	margin-bottom: 16px;
}

.sidebar-list {
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	flex-flow: column;
	margin-top: 0;
	margin-bottom: 0.75rem;
	padding-left: 0;
	display: flex;
}

.sidebar-list-item {
	color: #0915f8;
	padding-left: 0;
	font-family: var( --body-font-family );
	text-decoration: none;
}

.heading-18-copy {
	color: var(--delta-blue);
	margin-top: 16px;
	margin-bottom: 0;
	font-family: var( --body-font-family );
	font-size: 24px;
	font-weight: 700;
}

.h3-header-link {
	color: var(--delta-blue);
	font-size: 24px;
	font-weight: 400;
	line-height: 30px;
	transition: all 0.2s;
	display: inline-block;
}

.h3-header-link:hover {
	background-color: #0000;
}

.heading-18-copy-copy {
	color: var(--delta-blue);
	margin-bottom: 0;
	padding-bottom: 0;
	font-family: var( --body-font-family );
	font-size: 32px;
	font-weight: 700;
}

.main-content-column {
	width: 100%;
	max-width: 900px;
	padding-left: 0;
	padding-right: 0;
}

.heading-11 {
	color: var(--delta-blue);
	text-transform: capitalize;
	margin-top: 16px;
	margin-bottom: 0;
	font-size: 18px;
	font-weight: 800;
}

.h2-header-link {
	color: var(--delta-blue);
	font-size: 32px;
	font-weight: 400;
	line-height: 2.25rem;
	transition: all 0.2s;
	display: inline-block;
}

.h2-header-link:hover {
	background-color: #0000;
	text-decoration: none;
}

.main-content-section {
	color: var( --body-font-color );
	flex-flow: column;
	width: 100%;
	max-width: 900px;
	margin-right: 2rem;
	display: flex;
}

.heading-18 {
	color: var(--delta-blue);
	margin-top: 16px;
	margin-bottom: 0;
	font-size: 22px;
	font-weight: 700;
}

.heading-10-copy {
	color: var(--delta-blue);
	text-transform: capitalize;
	margin-top: 16px;
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 800;
}

.left-nav-nested-list {
	background-color: var(--secondary-bg);
	border-radius: 16px;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.left-nav-nested-list._3rd {
	background-color: #09569b00;
}

.left-nav-nested-list-link {
	color: #333;
	text-transform: capitalize;
	border: 2px #0000;
	padding: 0.75em 1.5rem;
	font-family: var( --body-font-family );
	font-size: 0.85em;
	font-weight: 500;
	line-height: 1.375em;
	text-decoration: none;
	display: block;
}

.left-nav-nested-list-link:hover {
	color: #0915f8;
	background-color: #0000;
	background-image: none;
	border: 2px #48bdd7;
}

.left-nav-nested-list-link.grandchild-nav-link {
	color: #222;
	border-radius: 16px;
	font-size: 16px;
	font-weight: 400;
	transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.left-nav-nested-list-link.grandchild-nav-link:hover {
	background-color: var(--secondary-bg);
	border-radius: 16px;
	text-decoration: none;
}

.left-nav-nested-list-link.grandchild-nav-link.great {
	padding-left: 2.5rem;
}

.quote-content {
	color: var(--white);
	border-left-style: none;
	margin-bottom: 0;
	padding: 0;
	font-size: 1.3rem;
	font-style: italic;
	font-weight: 500;
	line-height: 1.75rem;
}

.left-nav-column {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	flex-flow: column;
	min-width: 300px;
	max-width: 300px;
	padding-left: 0;
	padding-right: 0;
	display: flex;
}

.link-7 {
	color: #fff;
	font-weight: 400;
	text-decoration: underline;
}

.link-7:hover {
	text-decoration: none;
}

.link-7.w--current {
	color: #28313b;
	font-weight: 700;
}

.body-2 {
	background-color: var(--secondary-bg);
	font-family: var( --body-font-family );
}

.main-btn {
	background-color: var(--delta-orange);
	color: var(--white);
	text-align: center;
	text-transform: capitalize;
	border-radius: 100px;
	justify-content: center;
	align-self: flex-start;
	align-items: center;
	padding: 10px 32px;
	font-family: Open Sans, sans-serif;
	font-size: 16px;
	font-weight: 700;
	text-decoration: none;
	transition-duration: 0.5s;
	display: inline-block;
	position: relative;
	overflow: hidden;
}

.main-btn:hover,
.main-btn.secondary {
	background-color: var(--delta-blue);
}

.main-btn.secondary:hover {
	background-color: var(--dark-delta);
}

.main-btn.tertiary {
	background-color: var(--main-bg);
	color: var(--dark-delta);
}

.main-btn.tertiary:hover {
	background-color: #9fc0e5;
}

.notification-banner {
	background-color: var(--dark-delta);
	color: #fff;
	justify-content: flex-start;
	align-self: auto;
	align-items: center;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 32px;
	display: flex;
}

.banner-wrap {
	color: #fff;
	justify-content: center;
	align-items: center;
	display: flex;
}

.text-block-10 {
	letter-spacing: 0.5px;
	margin-left: auto;
	margin-right: auto;
	font-size: 15px;
	font-weight: 400;
}

.brix---top-bar-close-icon {
	cursor: pointer;
	align-items: center;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: auto;
	transition: opacity 0.3s;
	display: flex;
	position: absolute;
	inset: auto 32px auto auto;
}

.brix---icon-small-hover {
	width: 20px;
	height: 20px;
	transition: transform 0.3s;
}

.brix---icon-small-hover:hover {
	transform: scale(0.9);
}

.inside-page-banner {
	background-image: url("/20181016082423/assets/images/AdobeStock_875844606.webp");
	background-position: 50% 34%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 250px;
}

.inside-page-banner.no-banner {
	background: none;
	height: 50px;
}

.dropdown-3 {
	margin-left: 0;
	margin-right: 0;
}

@media screen and (max-width: 991px) {
	.inside-page-banner.no-banner {
		display: none;
	}

	.content.provider {
		flex-flow: column;
		justify-content: flex-start;
		align-items: center;
		height: auto;
		padding: 32px;
	}

	.content-container {
		flex-direction: column !important;
		align-self: center;
		align-items: center !important;
	}

	.content-container.provider {
		padding: 64px 20px;
	}

	.section.mod--steps.blue {
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.section.mod--reviews.vertical {
		justify-content: flex-start;
		align-items: center;
		padding-left: 0;
		padding-right: 0;
	}

	.section.variation {
		justify-content: space-between;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding: 0;
		display: block;
	}

	.section.mod--reviews-copy {
		max-width: none;
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.home__content {
		justify-content: center;
		align-items: center;
		margin-left: auto;
	}

	.home__content.hero {
		height: auto;
	}

	.btn {
		color: #fff;
	}

	.subheading.mod--hero-copy {
		text-align: center;
	}

	.menu__nav-link {
		color: #fff;
		text-align: center;
		border-bottom: 1px solid #f2f2f233;
		padding-top: 20px;
		padding-bottom: 20px;
		font-weight: 600;
		text-decoration: none;
	}

	.menu__nav-link:hover,
	.menu__nav-link.w--current {
		background-position: 0;
		background-size: 10px;
	}

	.header__humb {
		z-index: 0;
		cursor: pointer;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		display: flex;
		position: relative;
	}

	.header__humb-close {
		z-index: 2;
		padding: 0;
		display: none;
		position: absolute;
		inset: 0%;
	}

	.section-slide.footer-container {
		display: block;
	}

	.section-slide.white {
		padding-left: 48px;
		padding-right: 48px;
	}

	.form-2 {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		justify-content: center;
		align-items: center;
		display: grid;
	}

	.field {
		width: 100%;
	}

	.uui-navbar02_dropdown-right-overlay-absolute {
		border-radius: 0.5rem;
		min-width: 100%;
	}

	.uui-navbar02_dropdown-content-2 {
		flex-direction: column;
	}

	.wrapper {
		flex-flow: column;
		justify-content: center;
		width: 100%;
		max-width: none;
		margin-left: 0;
		margin-right: 0;
		padding-bottom: 100px;
		display: flex;
	}

	.uui-navbar02_dropdown-content-button-wrapper {
		margin-bottom: 0;
		margin-left: 0;
	}

	.uui-navbar02_blog-content {
		max-width: none;
	}

	.uui-navbar02_dropdown-content-right-2 {
		max-width: 50rem;
		margin-bottom: 1rem;
	}

	.search-button-icon {
		margin-top: 0;
	}

	.search-button-icon:hover {
		background-size: 35px;
	}

	.search-input-3 {
		padding-left: 0;
	}

	.search-icon {
		margin-left: 0;
		margin-right: 0;
	}

	.search-icon.desktop {
		display: none;
	}

	.exit-button-icon:hover {
		background-size: 35px;
	}

	.search-content {
		border-top: 1px solid #5b5b5b26;
		width: 75%;
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 0;
		top: 100%;
		right: auto;
	}

	.heading {
		text-align: center;
		font-size: 32px;
	}

	.heading.hero {
		text-align: left;
		font-size: 28px;
	}

	.tabs_menu-service {
		grid-column-gap: 0.5rem;
		grid-row-gap: 0.5rem;
		flex-flow: row;
		grid-template-columns: 1fr;
		display: flex;
	}

	.services-tabs_image {
		width: 100%;
		height: 18rem;
	}

	.margin-bottom {
		margin-top: 0;
		margin-left: 0;
		margin-right: 0;
	}

	.margin-top {
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 0;
	}

	.tabs_content {
		padding-left: 1rem;
	}

	.tabs-layout_component {
		grid-column-gap: 3rem;
		grid-template-columns: 1fr;
		grid-auto-flow: row;
		min-height: auto;
		display: flex;
		flex-direction: column;
	}

	.tabs1_link-service {
		width: auto;
		max-width: none;
		margin-left: 0.25rem;
		margin-right: 0.25rem;
		padding: 0.5rem 1.5rem;
	}

	.tabs1_link-service.w--current {
		margin-left: 0;
		margin-right: 0;
	}

	.tabs1_link-service.horizontal {
		grid-column-gap: 8px;
		grid-row-gap: 8px;
		color: var(--delta-blue);
		text-align: center;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		width: 125px;
		max-width: 200px;
		height: 100px;
		margin-left: 0;
		margin-right: 0;
	}

	.tabs-layout_content {
		text-align: center;
	}

	.heading-3 {
		font-size: 32px;
	}

	.headingwrap.blue.alternative {
		justify-content: center;
		align-items: center;
		margin-bottom: 0;
	}

	.footer-nav-wrapper._3 {
		padding-top: 80px;
	}

	.footer-nav-wrapper._1f {
		padding-top: 32px;
	}

	.footer-container {
		justify-content: center;
		align-items: center;
		padding-top: 32px;
		display: flex;
	}

	.social-wrapper {
		flex-flow: column;
	}

	.sign-off-div-wrap {
		width: 100%;
		min-width: auto;
	}

	.column-2 {
		flex-direction: column;
		align-items: flex-end;
		padding-right: 0;
	}

	.footer-signoff-link-wrap {
		float: left;
		margin-bottom: 1.5rem;
		display: block;
	}

	.footer-signoff-links {
		margin-left: 0;
		margin-right: 0.75rem;
	}

	.grip-logo-link {
		float: right;
		margin-bottom: 1rem;
		margin-left: 0;
		margin-right: 0;
	}

	.column-3 {
		flex-direction: column;
		align-items: flex-start;
		display: flex;
	}

	.footer-copyright-2 {
		float: left;
		margin-bottom: 0.25rem;
		margin-right: 0;
	}

	.textwithicon {
		width: auto;
		font-size: 0.9rem;
	}

	.iconwrap {
		align-self: center;
		width: 35px;
		max-width: none;
		height: 35px;
		margin-bottom: 0;
	}

	.iconwrap.horizontal {
		width: auto;
		max-width: 40px;
		max-height: 40px;
	}

	.iconwrap.horizontal.small {
		max-width: 30px;
		max-height: 30px;
	}

	.html-embed {
		margin-left: auto;
		margin-right: auto;
	}

	.html-embed.alternative {
		height: auto;
	}

	.category-title {
		margin-bottom: 0;
	}

	.blog-article-wrap {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		padding-top: 32px;
		padding-left: 48px;
		padding-right: 48px;
		display: flex;
	}

	.blog-content-wrap {
		padding-top: 16px;
	}

	.card {
		flex-flow: column;
		justify-content: space-between;
		align-items: stretch;
		height: 100%;
		padding: 20px;
		display: flex;
	}

	.card.tabletlandscape {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		flex-flow: row;
		padding-bottom: 20px;
		display: flex;
	}

	.cardwrapper {
		width: 100%;
		height: 100%;
	}

	.heading-5 {
		font-size: 18px;
		line-height: 22px;
	}

	.top-nav-div {
		display: none;
	}

	.quick-links {
		place-content: center;
		align-items: center;
		display: flex;
	}

	.text-block-45 {
		font-size: 16px;
		line-height: 19px;
	}

	.heroslider {
		height: 600px;
	}

	.slide {
		padding-left: 32px;
	}

	.slide-nav-2 {
		justify-content: center;
		align-items: center;
		padding-bottom: 0;
		padding-left: 0;
		display: none;
	}

	.floatingimg {
		max-width: 150px;
		height: 100px;
		margin-bottom: 32px;
		margin-right: 0;
	}

	.search-icon-3 {
		background-size: 18px;
		width: 25px;
		margin-right: 0;
	}

	.location-icon {
		width: auto;
		max-width: 18px;
		max-height: 18px;
	}

	.search-input-4 {
		width: 500px;
	}

	.nav-link-icon-wrapper {
		background-color: var(--main-bg);
		border-radius: 5px;
		justify-content: center;
		align-items: center;
		width: 30px;
		height: 30px;
		margin-bottom: 0;
		padding: 4px;
		display: flex;
	}

	.nav-link-icon-wrapper.mobile-view {
		align-items: center;
		width: 25px;
		display: flex;
	}

	.navcontents {
		grid-column-gap: 8px;
		grid-row-gap: 8px;
		flex-flow: row;
		order: 0;
		justify-content: flex-end;
		align-items: center;
		display: flex;
	}

	.nav-icon {
		max-width: 20px;
	}

	.main-nav-link {
		display: block;
	}

	.nav-link-2.mobile {
		background-color: #0000;
		border-bottom-style: none;
		border-bottom-color: #ffffff1a;
		justify-content: space-between;
		width: 90%;
		padding: 20px;
		font-family: Lato, sans-serif;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1rem;
		display: block;
	}

	.nav-link-2.mobile.w--current {
		color: #f9b604;
		transform: scale(1);
		box-shadow: inset 6px 0 #f9b604;
	}

	.nav-link-2.mobile.secondary-nav-link {
		padding-left: 0;
	}

	.two-part-mobile-nav-wrapper-2 {
		background-color: var(--3rd-bg);
		color: var(--dark-delta);
		border-top: 1px #000;
		border-bottom: 1px solid #fff6;
		justify-content: flex-start;
		align-items: center;
		padding-top: 16px;
		padding-bottom: 16px;
		padding-left: 2rem;
		font-size: 16px;
		font-weight: 500;
		display: flex;
	}

	.two-part-mobile-nav-wrapper-2:hover {
		background-color: var(--delta-blue);
		color: var(--white);
	}

	.two-part-mobile-nav-wrapper-2.noicon {
		justify-content: center;
		align-items: center;
		width: 50%;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.two-part-mobile-nav-wrapper-2.noicon.blue {
		border-right: 1px solid var(--main-bg);
		background-color: var(--delta-blue);
		color: var(--white);
	}

	.two-part-mobile-nav-wrapper-2.noicon.blue:hover {
		background-color: var(--delta-orange);
	}

	.two-part-mobile-nav-wrapper-2.noicon.orange {
		background-color: var(--delta-orange);
		color: var(--white);
	}

	.two-part-mobile-nav-wrapper-2.noicon.orange:hover {
		background-color: var(--dark-delta);
	}

	.menu-icon {
		color: var(--dark-delta);
		font-size: 2rem;
	}

	.image-2 {
		width: 100px;
	}

	.right-top-header-links {
		order: -1;
		justify-content: flex-end;
		align-items: center;
		margin-right: 0;
		display: flex;
	}

	.logo-image {
		display: block;
	}

	.menu-button-2 {
		order: 1;
		align-self: flex-end;
		padding: 0.75rem 0 0.75rem 8px;
	}

	.menu-button-2.w--open {
		color: #163b68;
		background-color: #0000;
		border-radius: 3px;
	}

	.main-nav-link-wrapper {
		display: none;
	}

	.header-nav-wrapper {
		border-bottom-color: #6e6e6e26;
		flex-flow: row;
		justify-content: flex-end;
		width: 100%;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	.mobile-header-link-wrapper {
		order: 0;
		justify-content: flex-start;
		align-items: center;
		margin-left: auto;
		display: flex;
	}

	.top-header-nav-wrapper {
		justify-content: space-between;
		align-self: auto;
		margin-left: auto;
		padding: 0;
		position: relative;
		left: 0;
		right: 0;
	}

	.search-wrapper-2 {
		order: 1;
		width: 30px;
		margin-right: 1rem;
		padding-left: 0;
		padding-right: 0;
	}

	.right-side-nav-wrapper {
		flex-wrap: nowrap;
		order: -1;
		justify-content: space-between;
		width: 100%;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.mobile-arrow-5 {
		margin-left: 1.25rem;
		margin-right: 1.25rem;
		font-family: var( --body-font-family );
		font-size: 1rem;
		font-weight: 500;
		line-height: 1em;
	}

	.search-input-exit-2 {
		margin-right: 1.5rem;
	}

	.mobile-menu-arrow-5 {
		justify-content: center;
		align-items: center;
		margin-left: auto;
		margin-right: 16px;
		display: flex;
	}

	.mobile-menu-arrow-5:hover {
		background-color: #005c9626;
	}

	.top-header-nav-link {
		opacity: 1;
		padding-left: 0.25rem;
		padding-right: 0.25rem;
		display: none;
	}

	.top-header-nav-link:hover {
		color: #4d4d4f;
	}

	.top-header-nav-link.mychart {
		margin-left: 1rem;
		margin-right: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;
		display: flex;
	}

	.top-header-nav-link.schedule-appt-button {
		display: flex;
	}

	.top-header-nav-link.schedule-appt-button:hover {
		color: #fff;
	}

	.top-header-nav-link.last {
		margin-left: 0.5vw;
		margin-right: 0;
		padding: 1rem 0 1rem 0.5rem;
		display: none;
	}

	.search-text {
		display: none;
	}

	.nav-logo {
		order: -1;
		width: 100%;
		max-width: 325px;
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
		margin-right: auto;
	}

	.search-bar-2 {
		display: none;
		top: 0;
	}

	.main-header-wrapper {
		justify-content: space-between;
		display: flex;
	}

	.navitems {
		z-index: 5;
		background-color: #e4edf1;
		padding-top: 0;
		padding-bottom: 0;
	}

	.navbar-2 {
		flex-flow: column;
		display: flex;
	}

	.map-wrapper {
		background-size: contain;
		order: -1;
		width: 50%;
		height: 350px;
	}

	.heading-6 {
		max-width: none;
		font-size: 2.65rem;
	}

	.heading-wrapper {
		flex-direction: column;
		order: -1;
		width: 100%;
		padding-top: 0;
	}

	.heading-wrapper.our-locations {
		order: 1;
		width: 50%;
		padding-bottom: 3rem;
		padding-right: 0;
	}

	.text-block {
		max-width: none;
	}

	.locations-section {
		flex-flow: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: auto;
	}

	.slide-2 {
		width: 33.33%;
	}

	.clinics-slider .image-wrapper {
		height: 150px;
	}

	.slider {
		height: auto;
	}

	.arrow {
		margin-left: -50%;
		margin-right: -50%;
	}

	.arrow._2 {
		width: 30px;
		height: 30px;
		margin-left: 0%;
		margin-right: -3%;
	}

	.arrow._2.left {
		width: 35px;
		height: 35px;
		margin-left: -3%;
		margin-right: 0%;
	}

	.herotextcon {
		border-radius: 10px;
		max-width: 350px;
		padding: 24px;
	}

	.footerinfo {
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-auto-columns: 1fr;
		padding-bottom: 32px;
		display: grid;
	}

	.footer-link-label-new {
		margin-bottom: 10px;
	}

	.uui-navbar02_item-heading-2-copy-8 {
		color: #1d1d1b;
		font-size: 1.2rem;
	}

	.text-block-11 {
		color: #000;
		font-size: 1.2rem;
		font-weight: 500;
	}

	.uui-dropdown-icon-4 {
		color: #667085;
		margin-top: 0;
		margin-bottom: 0;
		margin-right: 0;
		display: flex;
		inset: auto 0% auto auto;
	}

	.uui-navbar02_dropdown-toggle-3 {
		color: #101828;
		align-items: center;
		margin-left: 0;
		margin-right: 0;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 0;
		font-size: 1.125rem;
		display: flex;
	}

	.uui-navbar02_dropdown-toggle-3:hover {
		color: #101828;
	}

	.uui-navbar02_dropdown-toggle-3.w--open {
		height: auto;
		padding-bottom: 0.75rem;
	}

	.dropdown-link-3 {
		padding-left: 0;
		padding-right: 0;
	}

	.dropdown-link-3:hover {
		background-color: #0000;
	}

	.nav_link {
		padding: 0.75rem 2.5rem;
		display: block;
	}

	.uui-navbar02_dropdown-list-3 {
		position: relative;
		overflow: hidden;
	}

	.uui-navbar02_dropdown-list-3.w--open {
		box-shadow: none;
		background-color: #0000;
		border-style: none;
		align-items: flex-start;
		width: 100%;
		min-width: auto;
		margin-top: 20px;
		margin-bottom: 0;
		margin-left: 0;
		padding: 0 0 0;
		position: relative;
		top: 0;
		left: 0;
		right: 0;
	}

	.uui-navbar02_container-3 {
		justify-content: flex-end;
	}

	.uui-navbar02_dropdown-content-left-3 {
		padding-left: 0;
		padding-right: 0;
	}

	.footer-link-label-new-copy {
		margin-bottom: 10px;
	}

	.div-block-131 {
		grid-column-gap: 8px;
		display: flex;
	}

	.dropdown-2 {
		display: none;
	}

	.left-side-secondary-content-box {
		width: 100%;
		margin-top: 32px;
	}

	.right-sidebar-column {
		border-top: 3px #48bdd7;
		width: 100%;
		max-width: none;
		margin-top: 0;
		padding-top: 0;
	}

	.div-block-152-copy {
		width: 100%;
		margin-top: 32px;
		margin-left: 0;
	}

	.image-block {
		width: 65%;
	}

	.main-content-wrapper {
		width: 100%;
		padding-top: 32px;
	}

	.sidebar-section-wrap {
		margin-left: 0;
	}

	.left-nav-list-content-wrap {
		height: 0;
		overflow: hidden;
	}

	.inside-page-header-wrap {
		margin-bottom: 1rem;
		padding-top: 0;
		padding-bottom: 1rem;
	}

	.inside-content {
		flex-flow: column;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.main-content-column {
		max-width: none;
		padding-left: 0;
		padding-right: 0;
	}

	.main-content-section {
		max-width: none;
	}

	.left-nav-column {
		display: none;
	}

	.notification-banner {
		align-items: center;
		display: flex;
	}

	.banner-wrap {
		margin-right: auto;
	}

	.brix---top-bar-close-icon {
		align-items: center;
		height: 30px;
		padding: 0 24px;
		position: static;
	}

	.brix---icon-small-hover {
		width: 20px;
		height: 20px;
	}

	.inside-page-banner {
		height: 200px;
	}

	.mobnavlink {
		margin-left: 16px;
		margin-right: 16px;
	}

	.left-arrow {
		margin-left: auto;
		margin-right: 0;
		display: flex;
		right: 0;
	}

	.rightarrow {
		margin-top: auto;
		margin-bottom: auto;
		font-size: 32px;
		top: 48px;
	}

	.leftarrow {
		margin-top: auto;
		margin-bottom: auto;
		font-size: 32px;
		top: -48px;
	}

	.dropdown-3 {
		width: 100%;
	}

	.special {
		justify-content: space-around;
		align-items: stretch;
		display: flex;
	}

	.arrow._2.left {
		left: -35px;
	}

	.arrow._2.w-slider-arrow-right {
		right: -35px;
	}

	.tabs-layout_content {
		order: 2;
	}

	.quick-links-image._1 {
		max-height: 100% !important;
		width: auto !important;
	}
}

@media screen and (max-width: 767px) {
	.content-container.provider {
		padding: 64px 20px;
	}

	.tabs-layout_content {
		order: 1;
	}

	.section.mod--courses {
		padding-left: 48px;
		padding-right: 48px;
	}

	.section.mod--steps.blue {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}

	.home__content {
		max-width: none;
	}

	.home__content.hero {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}

	.btn {
		align-self: center;
	}

	.subheading.mod--hero {
		display: none;
	}

	.footer__drop {
		width: 100%;
		margin-left: 0;
		margin-right: auto;
	}

	.uui-navbar02_blog-item {
		padding: 0;
	}

	.uui-navbar02_blog-item:hover {
		background-color: #0000;
	}

	.uui-button-row {
		align-self: stretch;
	}

	.uui-button-link {
		font-size: 1rem;
	}

	.uui-button-link:hover {
		color: #6941c6;
	}

	.wrapper {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		justify-content: center;
	}

	.uui-button-link-gray-2 {
		font-size: 1rem;
	}

	.uui-button-link-gray-2:hover {
		color: #475467;
	}

	.uui-navbar02_dropdown-content-right-2 {
		padding: 2rem 1rem;
	}

	.search-button-icon {
		background-size: 30px;
		margin-left: auto;
	}

	.search-button-icon:hover {
		background-size: 30px;
	}

	.search-input-3 {
		padding-left: 0;
		font-size: 0.8rem;
	}

	.search-icon {
		width: 35px;
		height: 35px;
	}

	.exit-button-icon {
		background-size: 30px;
		margin-left: auto;
	}

	.exit-button-icon:hover {
		background-size: 30px;
	}

	.search-content {
		border-bottom-right-radius: 10px;
		border-bottom-left-radius: 10px;
		width: 80vw;
		height: 60px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 1rem;
		padding-right: 1rem;
		display: flex;
		inset: 85% auto auto;
	}

	.heading {
		width: auto;
		font-size: 24px;
	}

	.heading.hero {
		text-align: center;
		margin-bottom: 16px;
		font-size: 18px;
		line-height: 24px;
	}

	.tabs_menu-service {
		grid-column-gap: 0.5rem;
		grid-row-gap: 0.5rem;
		grid-template-rows: auto auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		width: 100%;
		display: grid;
	}

	.margin-bottom {
		margin-top: 0;
		margin-left: 0;
		margin-right: 0;
	}

	.margin-top {
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 0;
	}

	.tabs_content {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.tabs-layout_component {
		grid-row-gap: 3rem;
		grid-template-columns: 1fr;
	}

	.tabs_component--service {
		margin-left: 0;
		margin-right: 0;
	}

	.tabs1_link-service {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		flex-flow: row;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		padding-left: 15%;
	}

	.tabs1_link-service.w--current {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		flex-flow: row;
	}

	.tabs1_link-service.horizontal {
		width: 105px;
		height: 95px;
		padding-left: 8px;
		padding-right: 8px;
		flex-wrap: wrap;
	}

	.footer-nav-wrapper._3,
	.footer-nav-wrapper._1f {
		text-align: center;
		justify-content: flex-start;
		align-items: center;
		width: auto;
		max-width: none;
		padding-top: 0;
	}

	.footer-column.footer1 {
		justify-content: center;
		align-items: center;
		margin-bottom: 32px;
		margin-right: 0;
	}

	.social-wrapper {
		margin-bottom: 32px;
	}

	.footer-brand {
		width: 100%;
	}

	.grip-logo-image {
		position: relative;
	}

	.column-2 {
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: relative;
	}

	.footer-signoff-link-wrap {
		float: none;
		display: inline-block;
	}

	.footer-signoff-links.last {
		margin-right: 0;
	}

	.grip-logo-link {
		float: none;
		justify-content: center;
		align-items: center;
		margin-bottom: 0;
		display: flex;
	}

	.column-3 {
		align-items: center;
		overflow: hidden;
	}

	.footer-copyright-2 {
		float: none;
	}

	.signoff-div {
		flex-flow: column;
		padding-left: 32px;
		padding-right: 32px;
	}

	.columns-2 {
		flex-flow: column;
		justify-content: center;
		align-items: center;
	}

	.textwithicon {
		font-size: 14px;
		line-height: 18px;
	}

	.blog-article-wrap {
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		padding-left: 32px;
		padding-right: 32px;
		display: flex;
	}

	.card {
		width: 100%;
		margin-bottom: 35px;
	}

	.card.tabletlandscape {
		margin-bottom: 0;
	}

	.top-nav-div {
		display: none;
	}

	.quick-links {
		grid-column-gap: 8px;
		grid-row-gap: 8px;
		padding-left: 16px;
		padding-right: 16px;
	}

	.heroslider {
		background-color: #fff;
	}

	.slide {
		height: 450px;
		padding-top: 0;
		padding-bottom: 0;
		padding-right: 32px;
	}

	.slide._2 {
		vertical-align: baseline;
	}

	.sliderwrap {
		margin-left: 0;
		margin-right: 0;
		padding-bottom: 64px;
		position: absolute;
		inset: 149px 0% 0%;
	}

	.floatingimg {
		margin-right: 0;
	}

	.search-icon-3 {
		background-size: 18px;
		height: 30px;
	}

	.search-input-4 {
		width: 450px;
	}

	.navcontents {
		width: auto;
	}

	.nav-link-2.mobile {
		width: 84%;
		padding-left: 20px;
	}

	.nav-link-2.mobile.secondary-nav-link {
		width: 90%;
		padding-left: 0;
	}

	.image-2 {
		width: 100px;
	}

	.right-top-header-links {
		order: -1;
		margin-right: 0;
	}

	.menu-button-2 {
		order: 1;
	}

	.header-nav-wrapper {
		padding: 0.75rem 1.25rem;
	}

	.top-header-nav-wrapper {
		justify-content: space-between;
	}

	.search-wrapper-2 {
		order: 1;
		margin-right: 0.5rem;
		padding: 0.5rem 0;
	}

	.right-side-nav-wrapper {
		display: none;
	}

	.search-input-exit-2 {
		margin-right: 1.5rem;
	}

	.top-header-nav-link.mychart {
		justify-content: center;
		min-width: 130px;
		margin-left: 0;
		margin-right: 0;
		padding: 0.5rem 0;
	}

	.top-header-nav-link.schedule-appt-button {
		display: none;
	}

	.top-header-nav-link.schedule-appt-button.mobileversion {
		background-position: 50%;
		width: 35px;
		height: 35px;
		padding: 1rem;
		display: flex;
	}

	.search-text {
		display: none;
	}

	.nav-logo {
		padding-left: 0;
	}

	.search-bar-2 {
		padding-left: 1.25rem;
		display: none;
		top: 0;
	}

	.navitems {
		opacity: 1;
	}

	.heading-wrapper.our-locations {
		justify-content: flex-start;
		align-items: flex-start;
	}

	.text-block {
		margin-bottom: 1rem;
	}

	.locations-section {
		width: 80vw;
	}

	.slide-2 {
		width: 100%;
		padding: 40px;
	}

	.clinic-block {
		padding: 26px;
	}

	.herotextcon {
		border-radius: 0;
		flex-flow: column;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		max-width: none;
		margin-left: auto;
		margin-right: auto;
		display: flex;
	}

	.footerinfo {
		flex-flow: column;
		justify-content: flex-start;
		align-items: center;
		display: flex;
	}

	.uui-navbar02_dropdown-link-list-2 {
		grid-row-gap: 0.5rem;
		max-width: none;
	}

	.uui-navbar02_dropdown-toggle-3 {
		font-size: 1rem;
	}

	.nav_link {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	.uui-navbar02_dropdown-content-left-3 {
		grid-row-gap: 1.5rem;
		flex-direction: column;
		grid-template-rows: auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.right-sidebar-column {
		margin-top: 1.25rem;
		padding-top: 1.25rem;
	}

	.h5-header-link {
		font-size: 1.125rem;
	}

	.h4-header-link {
		font-size: 1.25rem;
	}

	.ordered-list-2 {
		padding-left: 20px;
	}

	.intro-paragraph-2 {
		font-size: 1.125rem;
	}

	.heading-7 {
		font-size: 2.3rem;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.block-quote-2 {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.main-content-wrapper {
		padding-left: 32px;
		padding-right: 32px;
	}

	.sidebar-section-wrap {
		margin-left: 0;
	}

	.inside-page-header-wrap {
		padding-left: 0;
		padding-right: 0;
		display: none;
	}

	.dividingline {
		height: 3px;
	}

	.h3-header-link {
		font-size: 1.5rem;
	}

	.heading-18-copy-copy,
	.h2-header-link {
		font-size: 28px;
	}

	.main-content-section {
		margin-right: 0;
	}

	.quote-content {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.notification-banner {
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 16px;
	}

	.text-block-10 {
		font-size: 14px;
		line-height: 18px;
	}

	.brix---top-bar-close-icon {
		align-items: center;
		padding-left: 16px;
		padding-right: 16px;
	}

	.brix---icon-small-hover {
		width: 24px;
	}

	.inside-page-banner {
		height: 150px;
	}

	.left-arrow {
		right: auto;
	}

	.rightarrow,
	.leftarrow {
		top: 0;
	}

	.content-2 .slick-dots {
		display: none !important;
	}

	.arrow._2.left {
		left: -25px;
	}

	.arrow._2.w-slider-arrow-right {
		right: -25px;
	}
}

@media screen and (max-width: 479px) {
	.content-container {
		margin-bottom: 32px;
	}

	.section.mod--courses {
		padding: 60px 0;
	}

	.section.mod--steps.blue {
		margin-left: 16px;
		margin-right: 16px;
		padding-top: 0;
		width: calc(100% - 32px);
	}

	.section.mod--reviews-copy {
		padding-top: 0;
		padding-bottom: 0;
	}

	.btn {
		align-self: center;
	}

	.btn.alt {
		flex: none;
		align-self: flex-start;
	}

	.swiper-wrapper {
		top: 585px;
	}

	.footer__drop {
		width: 100%;
		display: block;
	}

	.menu__nav-link {
		font-size: 14px;
	}

	.section-slide.mod--3 {
		padding-top: 140px;
		padding-left: 0;
		padding-right: 0;
	}

	.section-slide.mod--2 {
		padding-left: 16px;
		padding-right: 16px;
	}

	.section-slide.footer-container {
		padding-top: 0;
	}

	.section-slide.white {
		padding-left: 16px;
		padding-right: 16px;
	}

	.form-2 {
		grid-template-columns: 1fr;
	}

	.uui-navbar02_dropdown-content-wrapper {
		width: 100%;
	}

	.uui-navbar02_blog-item {
		flex-direction: column;
		display: flex;
	}

	.wrapper {
		padding-top: 32px;
		padding-bottom: 64px;
	}

	.uui-navbar02_dropdown-blog-item-wrapper {
		grid-column-gap: 0px;
		grid-row-gap: 0.5rem;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
	}

	.uui-navbar02_blog-content {
		margin-top: 0.5rem;
	}

	.uui-navbar02_dropdown-content-right-2 {
		max-width: none;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.search-button-icon {
		background-size: 28px;
		border-radius: 0;
		margin-top: 0;
		padding-left: 15px;
		padding-right: 15px;
	}

	.search-button-icon:hover {
		background-size: 28px;
		border-radius: 20px;
	}

	.search-input-3 {
		padding-left: 0;
	}

	.search-button-2 {
		padding: 0.65rem 0;
		font-size: 0.8rem;
	}

	.exit-button-icon {
		background-size: 28px;
		border-radius: 0;
		margin-top: 0;
		padding-left: 15px;
		padding-right: 15px;
	}

	.exit-button-icon:hover {
		background-size: 28px;
		border-radius: 20px;
	}

	.search-content {
		border: 1px solid var(--main-bg);
		align-items: center;
		width: 85vw;
		display: flex;
	}

	.location-card-wrapper {
		min-height: 325px;
	}

	.tabs_menu-service {
		grid-template-columns: 1fr 1fr;
	}

	.services-tabs_image {
		height: 14rem;
	}

	.margin-bottom {
		margin-top: 0;
		margin-left: 0;
		margin-right: 0;
	}

	.margin-top {
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 0;
	}

	.tabs-layout_component {
		grid-column-gap: 1rem;
		grid-row-gap: 1rem;
		grid-template-columns: 1fr;
	}

	.tabs1_link-service {
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		padding-left: 10%;
	}

	.tabs1_link-service.w--current {
		padding-left: 10%;
	}

	.tabs1_link-service.horizontal {
		flex-flow: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		max-width: none;
		height: 70px;
		padding: 1rem 8px;
	}

	.heading-2 {
		margin-bottom: 0;
		font-size: 24px;
	}

	.heading-3.secondary {
		text-align: center;
		font-size: 24px;
	}

	.headingwrap.blue {
		margin-bottom: 16px;
	}

	.headingwrap.blue.alternative {
		margin-top: 20px;
	}

	.accent.blue {
		width: 100%;
		min-width: 50px;
	}

	.footer-container {
		padding-left: 32px;
		padding-right: 32px;
	}

	.social-wrapper {
		margin-bottom: 32px;
	}

	.column-2 {
		flex-direction: column;
		align-items: center;
		padding-left: 0;
	}

	.footer-signoff-links.last {
		margin-right: 0;
	}

	.grip-logo-link {
		float: none;
		flex-direction: column;
		align-items: center;
		display: flex;
	}

	.column-3 {
		margin-bottom: 0.5rem;
	}

	.footer-copyright-2 {
		text-align: center;
	}

	.textwithicon {
		text-align: left;
	}

	.iconwrap {
		width: 30px;
		height: 30px;
	}

	.iconwrap.horizontal.small {
		max-width: 25px;
		height: 30px;
		max-height: 30px;
	}

	.category-title {
		margin-bottom: 0;
	}

	.blog-article-wrap {
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		flex-flow: column;
		padding-top: 16px;
		padding-left: 16px;
		padding-right: 16px;
		display: flex;
	}

	.blog-content-wrap {
		padding-top: 16px;
	}

	.card {
		margin-bottom: 0;
		padding-top: 10px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.card.tabletlandscape {
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		flex-flow: column;
	}

	.cardwrapper {
		height: auto;
		padding-bottom: 16px;
		padding-left: 0;
		padding-right: 0;
	}

	.btnwrap {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		margin-top: 0;
		padding-left: 16px;
		padding-right: 16px;
	}

	.btnwrap.alt {
		padding-left: 0;
		padding-right: 0;
	}

	.quick-links {
		grid-column-gap: 8px;
		grid-row-gap: 8px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		display: grid;
	}

	.sliderwrap {
		padding-bottom: 48px;
	}

	.floatingimg {
		margin-right: 0;
	}

	.search-icon-3 {
		background-size: 16px;
		width: 30px;
		height: 30px;
		margin-bottom: 5px;
		padding-left: 0;
		padding-right: 0;
	}

	.search-input-4 {
		width: 250px;
		margin-right: 1.25rem;
	}

	.nav-link-2.mobile {
		width: 80%;
	}

	.nav-link-2.mobile.secondary-nav-link {
		width: 90%;
		padding-left: 0;
	}

	.two-part-mobile-nav-wrapper-2.noicon.blue {
		border-right: 1px solid var(--main-bg);
	}

	.two-part-mobile-nav-wrapper-2.noicon.blue:hover {
		background-color: #d45d00;
	}

	.image-2 {
		margin-top: 0;
	}

	.right-top-header-links {
		background-color: #0000;
		border-bottom: 1px #d8d8d8;
		order: -1;
		margin-bottom: 0;
		margin-right: 0;
		padding-bottom: 0;
	}

	.menu-button-2 {
		order: 1;
	}

	.header-nav-wrapper {
		justify-content: space-between;
		align-items: center;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.search-wrapper-2 {
		order: 1;
		justify-content: center;
		width: 35px;
		margin-right: 0.25rem;
		padding: 0 1rem 0 0;
		display: flex;
	}

	.right-side-nav-wrapper {
		display: none;
	}

	.search-input-exit-2 {
		height: 25px;
		margin-right: 1rem;
	}

	.mobile-menu-arrow-5 {
		width: 20%;
		position: relative;
	}

	.top-header-nav-link {
		text-align: center;
		margin-left: 0;
		margin-right: 0;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		font-size: 0.8rem;
	}

	.top-header-nav-link.mychart {
		order: -1;
		margin-right: auto;
		display: block;
	}

	.top-header-nav-link.last {
		text-align: center;
		border-right-style: none;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.nav-logo {
		flex: 0 auto;
		width: 100%;
		margin-right: 16px;
	}

	.search-bar-2 {
		padding-left: 1rem;
		display: none;
		top: 0;
	}

	.navitems {
		height: 100vh;
	}

	.map-wrapper {
		order: 1;
		width: 100%;
		height: 250px;
		margin-top: 32px;
	}

	.heading-6 {
		text-align: left;
		font-size: 2.15rem;
		line-height: 2.5rem;
	}

	.heading-wrapper.our-locations {
		justify-content: flex-start;
		align-self: center;
		align-items: center;
		width: 100%;
		padding-bottom: 0;
		padding-right: 0;
	}

	.heading-wrapper.our-locations .heading-3 {
		text-align: center;
		font-size: 24px;
	}

	.text-block {
		text-align: center;
		font-size: 1rem;
	}

	.locations-section {
		text-align: center;
		flex-flow: column;
		width: 80vw;
		padding-top: 1rem;
	}

	.mask {
		width: 95%;
		padding-top: 0;
		overflow: visible;
	}

	.clinics-slider h4 {
		margin-top: 0;
	}

	.slide-2 {
		padding: 0 32px;
	}

	.content-2 {
		max-width: none;
		margin-left: 0;
		margin-right: 0;
	}

	.arrow._2 {
		margin-right: 0%;
	}

	.arrow._2.left {
		width: 30px;
		height: 30px;
		margin-left: 0%;
	}

	.dropdown-link-3 {
		padding-left: 0;
	}

	.uui-navbar02_container-3 {
		width: 100%;
	}

	.uui-navbar02_dropdown-content-left-3 {
		grid-template-columns: 1fr;
		padding-top: 0;
		padding-bottom: 0;
	}

	.button-link {
		width: 45%;
		margin-right: 0.8rem;
	}

	.paragraph-12 {
		font-size: 0.9rem;
	}

	.right-sidebar-column {
		margin-top: 0;
		padding-top: 0;
	}

	.intro-paragraph-2 {
		line-height: 1.5rem;
	}

	.inside-pages-paragraph {
		font-size: 0.9rem;
	}

	.heading-7 {
		margin-bottom: 1rem;
		font-size: 2rem;
		line-height: 2.5rem;
	}

	.inner-image-content {
		background-position: 20%;
	}

	.inner-image-content.recent-blog-post {
		height: 250px;
	}

	.block-quote-2 {
		background-image: url("/20181016082423/assets/images/missouri-20---opacity.svg"), linear-gradient(to bottom, var(--delta-blue), var(--delta-blue));
		background-position: 95% 95%, 0 0;
		background-size: 35% 35%, auto;
	}

	.image-block {
		width: 100%;
		height: auto;
	}

	.image-block.inside-page {
		height: auto;
	}

	.main-content-wrapper {
		padding-top: 24px;
		padding-left: 24px;
		padding-right: 24px;
	}

	.button-styling {
		flex-wrap: wrap;
		padding-left: 0;
	}

	.heading-18-copy-copy,
	.h2-header-link {
		line-height: 32px;
	}

	.text-block-10 {
		font-size: 12px;
	}

	.brix---top-bar-close-icon {
		padding-left: 12px;
		padding-right: 8px;
	}

	.brix---icon-small-hover {
		width: 20px;
	}

	.inside-page-banner {
		height: 100px;
	}

	.left-arrow {
		right: auto;
	}

	.rightarrow,
	.leftarrow {
		top: 0;
	}

	.arrow._2.left {
		left: -15px;
	}

	.arrow._2.w-slider-arrow-right {
		right: -15px;
	}
}

#w-node-f4c4cb45-06b6-92f6-63a5-cc4acbffbb9e-cbffbb60 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

@media screen and (max-width: 991px) {
	#w-node-_34f45f09-c422-0ddc-05d3-ddc13d6add73-6e15f0d5,
	#w-node-_34f45f09-c422-0ddc-05d3-ddc13d6add86-6e15f0d5,
	#w-node-_34f45f09-c422-0ddc-05d3-ddc13d6add97-6e15f0d5,
	#w-node-_510c6c13-21d6-2ac6-a4eb-5cac8ad25ff7-6e15f0d5,
	#w-node-_613b33c0-bfe3-179e-2f22-80311b772f90-6e15f0d5 {
		grid-area: 1 / 1 / 2 / 2;
	}

	#w-node-b4b5621c-8940-b585-cb1d-c6ba14d9574f-6e15f0d5 {
		grid-area: span 1 / span 2 / span 1 / span 2;
	}

	#w-node-b4b5621c-8940-b585-cb1d-c6ba14d9576b-6e15f0d5 {
		grid-area: span 1 / span 1 / span 1 / span 1;
	}

	#w-node-bb9e5670-35cc-2272-d528-28729afa528e-9afa528c {
		grid-area: span 1 / span 4 / span 1 / span 4;
		justify-self: center;
	}
}

@media screen and (max-width: 479px) {
	#w-node-bb9e5670-35cc-2272-d528-28729afa52ac-9afa528c {
		grid-area: span 1 / span 1 / span 1 / span 1;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 1150px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Webflow **********************/
/*****************************************************/
@media (min-width: 992px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="b53c5d61-c6b5-c645-5a77-da8e26a6cb0a"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="b53c5d61-c6b5-c645-5a77-da8e26a6cb0c"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="81b9f710-e317-b199-96ae-7b2e320be0de"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="fa3bb660-e29c-a753-858f-5e05f88e82c0"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="fa3bb660-e29c-a753-858f-5e05f88e82c5"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="f265153e-f885-075e-d8ab-795fbf5178d1"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="f265153e-f885-075e-d8ab-795fbf5178d3"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="fe85bb57-d8d3-2f0a-2a0b-9558e74dd922"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="fe85bb57-d8d3-2f0a-2a0b-9558e74dd926"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="fe85bb57-d8d3-2f0a-2a0b-9558e74dd928"] {
		opacity: 0;
	}
}

@media (max-width: 991px) and (min-width: 768px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="fa3bb660-e29c-a753-858f-5e05f88e82c0"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="63255519-91da-edcb-a563-381c761659a8"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="fa3bb660-e29c-a753-858f-5e05f88e82c5"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="fe85bb57-d8d3-2f0a-2a0b-9558e74dd928"] {
		opacity: 0;
	}
}

@media (max-width: 767px) and (min-width: 480px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="fa3bb660-e29c-a753-858f-5e05f88e82c0"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="63255519-91da-edcb-a563-381c761659a8"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="fa3bb660-e29c-a753-858f-5e05f88e82c5"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="fe85bb57-d8d3-2f0a-2a0b-9558e74dd928"] {
		opacity: 0;
	}
}

@media (max-width: 479px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="fa3bb660-e29c-a753-858f-5e05f88e82c0"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="63255519-91da-edcb-a563-381c761659a8"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="fa3bb660-e29c-a753-858f-5e05f88e82c5"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="fe85bb57-d8d3-2f0a-2a0b-9558e74dd928"] {
		opacity: 0;
	}
}

:root {
	scrollbar-color: rgb(34 103 165) #e5ecf4;
	scrollbar-width: thin;
}

.w-webflow-badge {
	display: none !important;
}

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-thumb {
	background: rgb(34 103 165);
	border-radius: 10px;
}

::-webkit-scrollbar-track {
	background: #e5ecf4;
	padding: 0px 1px;
}

.custom-scrollbar {
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 8px;
	padding: 0px 1px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background: rgb(34 103 165);
	border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
	background: #e5ecf4;
	padding: 0px 1px;
	border-radius: 0px 10px 10px 0px;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*********************** Header **********************/
/*****************************************************/
.alert-wrap {
	display: none;
}

.notification-banner {
	animation: fadeInAlert ease 1s;
}

.search-icon-hide {
	display: none;
}

.search-content {
	height: 0;
	opacity: 0;
	visibility: hidden;
	transition: all 400ms ease;
}

.search-content.active {
	opacity: 1;
	height: 80px;
	visibility: visible;
}

.header-container {
	position: sticky;
	top: 0;
	z-index: 100;
}

.header-container.sticky .alert-wrap {
	display: none !important;
}

.mm-wrapper_opened .header-container {
	z-index: 1000 !important;
}

.bold-text.contact {
	min-width: 72px;
}

@keyframes fadeInAlert {
	0% {
		opacity: 0;
		transform: translate3d(0px, -25px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	}

	100% {
		opacity: 1;
		transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	}
}


/***************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu {
	display: flex;
	z-index: 1000;
	align-items: flex-end;
	justify-content: flex-end;
}

.accesible-navigation-menu ul {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	display: flex;
	align-items: center;
}

.accesible-navigation-menu ul.nav-desktop-ul {
	position: relative;
	top: -2px;
}

.accesible-navigation-menu ul li.main-nav-link-wrapper {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-flow: row;
}

.accesible-navigation-menu ul li.main-nav-link-wrapper:last-child {
	margin-right: 0px;
}

.accesible-navigation-menu li.main-nav-link-wrapper#menu-item-services:before {
	content: "";
	display: inline-block;
	background-image: url('/assets/images/health-care-3.svg');
	width: 20px;
	height: 20px;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	background-position: center left;
	top: 20px;
}

.accesible-navigation-menu li.main-nav-link-wrapper#menu-item-clinics:before {
	content: "";
	display: inline-block;
	background-image: url('/assets/images/blue_location.svg');
	width: 18px;
	height: 20px;
	background-size: 18px 20px;
	background-repeat: no-repeat;
	background-position: center left;
	top: 20px;
}

.accesible-navigation-menu li.main-nav-link-wrapper#menu-item-resources:before {
	content: "";
	display: inline-block;
	background-image: url('/assets/images/patient-resources.svg');
	width: 20px;
	height: 20px;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	background-position: center left;
	top: 20px;
}

.accesible-navigation-menu li.main-nav-link-wrapper#menu-item-careers:before {
	content: "";
	display: inline-block;
	background-image: url('/assets/images/job-svgrepo-com.svg');
	width: 20px;
	height: 20px;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	background-position: center left;
	top: 20px;
}

.accesible-navigation-menu li.main-nav-link-wrapper#menu-item-foundation:before {
	content: "";
	display: inline-block;
	background-image: url('/assets/images/svg_foundation.svg');
	width: 21px;
	height: 18px;
	background-size: 21px 18px;
	background-repeat: no-repeat;
	background-position: center left;
	top: 20px;
}

.accesible-navigation-menu li.main-nav-link-wrapper .main-nav-link {
	padding: 0rem 0 0rem 0.4rem;
}

.accesible-navigation-menu li.main-nav-link-wrapper.hasChildren {
	margin-right: 3vw;
}

.accesible-navigation-menu li.main-nav-link-wrapper.hasChildren .main-nav-link:after {
	content: "\e603";
	font-family: 'webflow-icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	color: #000;
	display: block;
	position: relative;
	top: 0;
	left: 5px;
	right: 0;
}

.accesible-navigation-menu li.main-nav-link-wrapper .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: 0;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
	margin-top: 0px;
	width: 100%;
	padding-left: 0px;
	background-position: 25px top;
	background-repeat: no-repeat;
	background-size: 24px;
}

.accesible-navigation-menu li.main-nav-link-wrapper .container-wrapper ul {
	display: none;
	margin: 0px;
	list-style-type: none;
	width: 100%;
	flex-wrap: wrap;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 2px 5px #0003;
}

.accesible-navigation-menu li.main-nav-link-wrapper .container-wrapper ul li {
	width: 100%;
	text-align: left;
	padding: 0px 0px;
	font-size: 1rem;
	line-height: 1rem;
}

.accesible-navigation-menu li.main-nav-link-wrapper .container-wrapper ul li a {
	color: #222;
	text-decoration: none;
	font-family: var( --body-font-family );
	font-size: 1rem;
	display: inline-block;
	width: 100%;
	line-height: 1rem;
	padding: 16px;
	border-radius: 5px;
	border-bottom: 1px solid transparent;
}

.accesible-navigation-menu li.main-nav-link-wrapper .container-wrapper ul li a:hover {
	background-color: #0061a033;
	border-radius: 5px;
}

.accesible-navigation-menu li.main-nav-link-wrapper.open .container-wrapper,
.accesible-navigation-menu li.main-nav-link-wrapper:hover .container-wrapper {
	opacity: 1;
	max-height: 500px;
	margin-top: 0;
	height: auto;
	padding-top: 17px;
	min-width: 225px;
	max-width: 225px;
}

.accesible-navigation-menu li.main-nav-link-wrapper.open .container-wrapper ul,
.accesible-navigation-menu li.main-nav-link-wrapper:hover .container-wrapper ul {
	display: inline-block;
	padding: 6px;
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}

	.mm-page.mm-slideout {
		width: 100% !important;
		display: inherit !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: flex !important;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: flex !important;
	}
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: flex !important;
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mobile-navigation-menu,
.mm-panels,
.mm-panels .mm-panel,
.mm-navbar {
	background-color: #e4edf1;
}

.mm-wrapper_opened .w-nav-overlay {
	display: none !important;
}

.mobile-navigation-menu li a.nav-a {
	color: #004976;
	font-family: var( --body-font-family );
	font-weight: 500;
	font-size: 1rem;
	padding: 17px 30px;
}

.mm-navbar__title > span {
	color: #004976;
	font-family: var( --body-font-family );
	font-weight: 500;
	font-size: 1rem;
}

.mobile-navigation-menu li > a.nav-a:hover,
.mobile-navigation-menu li > a.nav-a.active {
	background-color: var(--delta-blue);
	color: var(--white);
}

.mm-navbar > * {
	justify-content: flex-start;
}

.mm-listitem_selected > .mm-listitem__text {
	background: transparent;
}

.mm-listitem_vertical > .mm-panel {
	padding: 0px;
}

.mm-navbar {
	border-color: #fff6;
}

.mm-listitem:after {
	border-color: #fff6;
	left: 0;
}

.mm-btn_prev:before {
	border-color: #034369;
	border-width: 1.9px;
}

.mm-btn_next:after {
	border-color: #034369;
	border-width: 1.9px;
}

.mm-listitem__btn {
	border-color: transparent;
}

.mm-listitem_opened > .mm-listitem__btn,
.mm-listitem_opened > .mm-panel {
	background: transparent;
}

.mobile-navigation-menu .nav-icon {
	background-color: var(--main-bg);
	border-radius: 5px;
	width: 30px;
	max-width: 30px;
	padding: 5px;
	margin-right: 10px;
	height: 30px;
}

/********************* Homepage *********************/
/*****************************************************/
.cbtext p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit !important;
}

.cbtext a {
	color: inherit;
	text-decoration: underline;
}

.cbtext a:hover {
	text-decoration: none;
}

.cbtext.white p,
.cbtext.white a,
.cbtext.white li,
.cbtext.white span,
.cbtext.white h1,
.cbtext.white h2,
.cbtext.white h3,
.cbtext.white h4,
.cbtext.white h5,
.cbtext.white h6 {
	color: #fff;
}

.quick-links-image._1 {
	width: 29px;
}

.quick-links-image._2 {
	width: 40px;
}

.quick-links-image._3 {
	width: 46px;
}

.quick-links-image._4 {
	width: 45px;
}

.tabs1_link-service.horizontal {
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.homepage-search-field-container {
	background-color: #fff;
	color: #0061a0;
	border-radius: 100px;
	width: 100%;
	padding: 9px 20px 3px 20px;
	transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.homepage-search-field-container .homepage-search-field-select {
	background-color: #fff;
	color: #0061a0;
	border: 0;
	max-width: 100%;
	font-size: 1rem;
	line-height: normal;
	padding: 4px;
}

.homepage-search-field-container .homepage-search-field-provider {
	min-width: 140px;
}

.homepage-search-field-container .select2-container {
	max-width: 100%;
	width: 100% !important;
}

.homepage-search-field-container.homepage-search-field-physician .select2-container {
	min-width: 140px;
}

.homepage-search-field-container .select2-container--default .select2-selection--single {
	background-color: #fff;
	border: 1px solid #fff;
	border-radius: 4px;
}

.homepage-search-field-container .select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 28px;
	color: #0061a0;
	line-height: normal;
	font-size: 1rem;
}


.homepage-search-field-container .select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #0061a0 transparent transparent transparent;
	margin-top: -5px;
}

.homepage-search-field-container .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #000 transparent;
}

.clinics-slider .slick-list {
	padding-top: 20px;
	margin-top: -20px;
}

/*********************** Slideshow ********************/
/*****************************************************/
.slider-text p {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
}

.slider-text p:last-child {
	margin-bottom: 0px;
}

.heroslider {
	animation: fadeSlider ease 0.5s;
}

@keyframes fadeSlider {
	0% {
		opacity: 0;
		transform: translate3d(0px, 43px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	}

	100% {
		transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
		opacity: 1;
	}
}


/****************** Inside Page ******************/
/*************************************************/
@media screen and (max-width: 991px) {
	iframe[src*="youtube"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.breadcrumb-list li {
	display: flex;
	align-items: center;
}

.breadcrumb-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumb-list li:last-child .breadcrumb-list-item,
.breadcrumb-list li:last-child:hover .breadcrumb-list-item {
	background-color: var(--main-bg);
}

.breadcrumb-list li:last-child .breadcrumb-link-1 {
	border-bottom: 0.5px solid #0000;
	font-weight: 700;
	line-height: 1rem;
	text-decoration: none;
}

.left-nav-list-link.active {
	background-color: var(--secondary-bg);
	color: #272727;
	text-decoration: none;
}

.left-nav-nested-list-link.grandchild-nav-link.active {
	background-color: var(--secondary-bg);
	border-radius: 16px;
	text-decoration: none;
}

.main-content-wrapper.only-right,
.main-content-wrapper.only-left,
.main-content-wrapper.no-columns {
	max-width: 1050px;
}

.main-content-wrapper.no-columns .inside-page-header-wrap {
	max-width: 900px;
}

.main-content-wrapper.no-columns {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.main-content-wrapper.no-columns .inside-content {
	width: 100%;
	max-width: 900px;
}

.main-content-section ul {
	list-style-type: disc;
	padding-left: 30px;
	margin-top: 15px;
	margin-bottom: 0;
}

.main-content-section ol {
	list-style-type: auto;
	padding-left: 30px;
	margin-top: 15px;
	margin-bottom: 15px;
}

/************** Right Sidebar ********************/
/*************************************************/
.right-sidebar-column td img {
	max-width: none;
}

.right-sidebar-column .box {
	margin-bottom: 30px;
}

.right-sidebar-column .box.tools {
	padding: 17px;
}

.right-sidebar-column .box p {
	color: #fff;
}

.right-sidebar-column .box h4,
.right-sidebar-column .box h3 {
	color: var(--white);
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 27px;
}

.right-sidebar-column .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-sidebar-column .box .contentbox_item_image {
	width: 24px;
}

.right-sidebar-column .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar-column .box.tools td:first-child img {
	margin-right: 5px;
}

.right-sidebar-column a {
	color: var(--white);
	font-weight: 400;
	text-decoration: underline;
}

.right-sidebar-column a:hover {
	text-decoration: none;
}

.right-sidebar-column p,
.right-sidebar-column td,
.right-sidebar-column li,
.right-sidebar-column a,
.right-sidebar-column span,
.right-sidebar-column h1,
.right-sidebar-column h2,
.right-sidebar-column h3,
.right-sidebar-column h4,
.right-sidebar-column h5,
.right-sidebar-column h6 {
	color: #fff !important;
}

/*************** Left Sidebar ********************/
/************************************************/
.left-sidebar .box {
	margin-bottom: 30px;
}

.left-sidebar .box,
.left-sidebar .box p {
	font-size: 16px;
	font-weight: 400;
}

.left-sidebar .box a {
	color: var(--delta-blue);
	font-weight: 400;
	text-decoration: underline;
}

.left-sidebar .box a:hover {
	text-decoration: none;
}

.left-sidebar .box h1,
.left-sidebar .box h2,
.left-sidebar .box h3,
.left-sidebar .box h4,
.left-sidebar .box h5,
.left-sidebar .box h6 {
	color: var(--delta-blue);
}

.left-sidebar .box h4 {
	color: var(--delta-blue);
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 27px;
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.main-content-section a {
	color: var(--delta-blue);
	font-weight: 400;
	text-decoration: underline;
}

.main-content-section a:hover {
	text-decoration: none;
}

h1 {
	color: var(--delta-blue);
	background-color: #0000;
	margin-top: 0;
	margin-bottom: 2rem;
	padding: 0;
	font-family: var( --body-font-family );
	font-size: 2.75rem;
	font-weight: 800;
}

h2 {
	color: var(--delta-blue);
	margin-top: 10px;
	margin-bottom: 10px;
	padding-bottom: 0;
	font-family: var( --body-font-family );
	font-size: 32px;
	font-weight: 700;
}

h3 {
	color: var(--delta-blue);
	margin-top: 16px;
	margin-bottom: 0;
	font-family: var( --body-font-family );
	font-size: 24px;
	font-weight: 700;
}

h4 {
	color: var(--delta-blue);
	margin-top: 16px;
	margin-bottom: 0;
	font-size: 22px;
	font-weight: 700;
}

h5 {
	color: var(--delta-blue);
	text-transform: capitalize;
	margin-top: 16px;
	margin-bottom: 0;
	font-size: 18px;
	font-weight: 800;
}

h6 {
	color: var(--delta-blue);
	text-transform: capitalize;
	margin-top: 16px;
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 800;
}

@media screen and (max-width: 767px) {
	h1 {
		font-size: 2.3rem;
	}

	h2 {
		font-size: 28px;
	}
}

@media screen and (max-width: 479px) {
	h1 {
		margin-bottom: 1rem;
		font-size: 2rem;
		line-height: 2.5rem;
	}
}

h1 a {
	color: var(--delta-blue);
	font-size: inherit;
	font-weight: 400;
	line-height: inherit;
	transition: all 0.2s;
}

h2 a {
	color: var(--delta-blue);
	font-size: 32px;
	font-weight: 400;
	line-height: 2.25rem;
	transition: all 0.2s;
}

h3 a {
	color: var(--delta-blue);
	font-size: 24px;
	font-weight: 400;
	line-height: 30px;
	transition: all 0.2s;
}

h4 a {
	color: var(--delta-blue);
	font-size: 22px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.2s;
}

h5 a {
	color: var(--delta-blue);
	text-transform: capitalize;
	font-size: 18px;
	font-weight: 400;
	line-height: 20px;
	transition: all 0.2s;
}

h6 a {
	color: var(--delta-blue);
	text-transform: capitalize;
	font-size: 16px;
	font-weight: 400;
	line-height: 18px;
	transition: all 0.2s;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	text-decoration: none;
}

@media screen and (max-width: 767px) {
	h2 a {
		font-size: 28px;
	}

	h3 a {
		font-size: 1.5rem !important;
	}

	h4 a {
		font-size: 1.25rem !important;
	}

	h5 a {
		font-size: 1.125rem !important;
	}
}

@media screen and (max-width: 479px) {
	h2 a {
		line-height: 32px;
	}
}

.main-content-section {
	font-size: 1rem;
}

.main-content-section p {
	margin-top: 8px;
	padding-bottom: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.25rem;
}

.main-content-section li {
	padding-bottom: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.25rem;
	text-decoration: none;
	color: #222;
	margin-bottom: 8px;
	transform: none;
}

@media screen and (max-width: 479px) {
	.main-content-section li {
		text-align: left;
		font-size: 0.9rem;
	}

	.main-content-section p {
		font-size: 0.9rem;
	}
}

/* buttons */
.inside-row .button,
.inside-row a.primary,
.homeboxes a.primary,
.primary,
.inside-row button[type="submit"],
.inside-row .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	border: 0px;
	color: #ffffff !important;
	background-color: #c25400 !important;
	border-radius: 100px;
	padding: 10px 32px;
	font-family: Open Sans, sans-serif;
	font-size: 1rem;
	transition: all 0.5s;
	font-weight: 700;
	width: auto;
	text-decoration: none !important;
	margin: 5px;
	cursor: pointer;
	border-bottom: 1px solid transparent;
}

.inside-row .button:hover,
.inside-row a.primary:hover,
.homeboxes a.primary:hover,
.primary:hover,
.inside-row button[type="submit"]:hover,
.inside-row .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	color: #fff !important;
	background-color: #0061a0 !important;
}

.secondary,
.inside-row a.secondary,
.homeboxes a.secondary,
.inside-row button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-row .cms_form_button.secondary,
.inside-row .button.secondary,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger {
	display: inline-block;
	border: 0px;
	color: #ffffff !important;
	background-color: #0061a0 !important;
	border-radius: 100px;
	padding: 10px 32px;
	font-family: Open Sans, sans-serif;
	font-size: 1rem;
	transition: all 0.5s;
	font-weight: 700;
	width: auto;
	text-decoration: none !important;
	margin: 5px;
	cursor: pointer;
	border-bottom: 1px solid transparent;
}

.secondary:hover,
.inside-row a.secondary:hover,
.inside-row button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-row .cms_form_button.secondary:hover,
.inside-row .button:hover,
.homeboxes a.secondary:hover,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger:hover {
	color: #fff !important;
	background-color: #004976 !important;
}

.tertiary,
.inside-row button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-row a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	border: 0px;
	color: #004976 !important;
	background-color: #d6e2f0 !important;
	border-radius: 100px;
	padding: 10px 32px;
	font-family: Open Sans, sans-serif;
	font-size: 1rem;
	transition: all 0.5s;
	font-weight: 700;
	width: auto;
	text-decoration: none !important;
	margin: 5px;
	cursor: pointer;
	border-bottom: 1px solid transparent;
}

.tertiary:hover,
.inside-row a.tertiary:hover,
.inside-row button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	color: #004976 !important;
	background-color: #9fc0e5 !important;
}

/* messages */
#message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	background-color: #11b91e40;
	border-left: 6px solid #11b91e;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.2rem;
}

#message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	background-color: #fd363626;
	border-left: 6px solid #c70616;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.2rem;
}

#message.success p {
	font-size: 1rem;
	font-weight: 400;
	font-family: var( --body-font-family );
	line-height: 1.2rem;
	color: #000;
}

#message.error p {
	font-size: 1rem;
	font-weight: 400;
	font-family: var( --body-font-family );
	line-height: 1.2rem;
	color: #000;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid #fff;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #004976;
	opacity: 100%;
	color: white;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #0061a0;
	opacity: 100%;
	color: white;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
	transition: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
	font-family: var( --body-font-family );
}

table.styled.striped tr:nth-child(even) {
	background: #d6e2f0;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

.gr-form {
	background-color: #fff;
}

hr {
	background-color: var(--main-bg);
	border-radius: 20px;
	width: 100%;
	height: 5px;
	margin-top: 16px;
	margin-bottom: 16px;
	border: 0px;
}

/* forms */
.inside-row input[type="text"],
.inside-row input[type="tel"],
.inside-row input[type="password"],
.inside-row input[type="email"],
.inside-row select,
.inside-row textarea,
.inside-row input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #333;
	font-size: 0.875rem;
	margin-bottom: 10px;
	background-color: #fff;
	color: #000;
}

.inside-row textarea {
	resize: vertical;
}

.inside-row label {
	font-weight: 400;
}

.inside-row option {
	font-weight: 600;
}

@media (max-width: 1149px) {
	.inside-row input[type="text"],
	.inside-row input[type="tel"],
	.inside-row input[type="password"],
	.inside-row input[type="email"],
	.inside-row select,
	.inside-row textarea,
	.inside-row input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	background-image: url('/20181016082423/assets/images/missouri-20---opacity.svg'), linear-gradient(to bottom, var(--delta-blue), var(--delta-blue));
	background-position: 95%, 0 0;
	background-repeat: no-repeat, repeat;
	background-size: auto 80%, auto;
	border-radius: 10px;
	margin-top: 8px;
	margin-bottom: 8px;
	padding: 32px;
	color: var(--white);
	font-size: 1.3rem;
	font-style: italic;
	font-weight: 500;
	line-height: 1.75rem;
	border-left: 0px;
}

.grip-grid > div {
	background-color: transparent !important;
}

blockquote p {
	font-size: inherit !important;
	line-height: inherit !important;
	font-family: inherit !important;
	color: inherit !important;
	font-weight: inherit !important;
}

blockquote p:last-child {
	margin-bottom: 0px;
}

@media screen and (max-width: 767px) {
	blockquote {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}
}

@media screen and (max-width: 479px) {
	blockquote {
		background-image: url('/20181016082423/assets/images/missouri-20---opacity.svg'), linear-gradient(to bottom, var(--delta-blue), var(--delta-blue));
		background-position: 95% 95%, 0 0;
		background-size: 35% 35%, auto;
	}
}

@media (max-width: 479px) {
	ul li,
	ol li {
		text-align: left;
	}
}

/********************** Footer **********************/
/****************************************************/
.phone-desktop {
	display: inline-block;
}

.phone-mobile {
	display: none;
	color: #09569b;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

#GRIPFooterLogo {
	padding-top: 0px !important;
	line-height: 11px;
	opacity: 0.6;
}

#GRIPFooterLogo:hover {
	opacity: 1;
}

.footer-list {
	margin: 0px;
	padding: 0px;
}

.footer-list li {
	margin: 0px;
	padding: 0px;
	display: inline-block;
	width: 100%;
}

@media screen and (max-width: 991px) {
	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline-block;
	}
}

@media screen and (max-width: 767px) {
	#GRIPFooterLogo {
		margin: 0 auto;
		float: none;
	}
}

@media screen and (max-width: 479px) {
	.footer-list li {
		text-align: center;
	}
}

/****************** Content Modules ****************/
/***************************************************/
/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: #fff;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: bold;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-fieldset .ui-form-legend {
	text-transform: none;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

.ui-element-with-example .source-code {
	overflow: hidden;
}

.ui-form-label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

#ui-datepicker-div {
	font-size: 0.9rem;
}

/* search */
.search-collection > .search-node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

@media (max-width: 991px) {
	.module-search button {
		margin-top: 10px;
	}
}

/* accounts */
.login-row__login #account_module.form .form_item .label,
.login-row__login #account_module.form .form_item.required .label {
	width: auto;
}

#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

.module-members .ui-form-tabs ul li {
	padding-top: 2px;
}

@media (min-width: 1150px) {
	#account_module.form .required_message {
		margin-right: 120px;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* submission forms */
#submissionforms_module {
	text-align: left;
}

#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper {
	margin-bottom: 30px;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 1rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .sigPad input,
#submissionforms_module.cms_form .sigWrapper.current {
	border-color: #666;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary {
	margin-right: 15px;
}

/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

#news_module.cms_list article time {
	font-weight: 500;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* gdpr */
#cookie-consent-container {
	background-color: #004976;
}

#cookie-consent-form-container form .consent-header {
	text-align: center;
}

#cookie-consent-form-container form .consent-header img {
	float: none;
	margin: 0 auto;
}

@media screen and (max-width: 1200px) {
	#cookie-consent-container .consent-actions .consent-actions-container {
		flex-direction: row;
		min-width: 230px;
	}
}

@media screen and (max-width: 767px) {
	#cookie-consent-container {
		display: flex;
		flex-direction: column;
	}

	#cookie-consent-container .consent-actions {
		order: 2;
	}

	#cookie-consent-container .consent-actions .consent-dismiss-trigger-container {
		position: absolute;
		top: 8px;
		right: 6px;
	}

	#cookie-consent-container .consent-actions a {
		margin-top: 5px;
	}
}

@media screen and (max-width: 479px) {
	#cookie-consent-container .consent-actions a.consent-all-trigger,
	#cookie-consent-container .consent-actions a.reject-all-trigger {
		font-size: 0.8rem;
	}

	#cookie-consent-container .consent-disclaimer {
		margin-top: 20px;
	}
}

/****************** Medical ******************/
/***************************************************/
.physician-search-accordion .physician-search-field .top-search-submit {
	line-height: 1.1rem !important;
}

.physician-search-accordion .physician-search-field .bottom-search-submit {
	background-size: 30px !important;
	font-size: 0px !important;
	width: 18% !important;
}

#clinics-module .md-section-h2 {
	margin-top: 1.3rem !important;
}

.md-list .md-list-item.md-list-item-split {
	float: none !important;
	width: 100% !important;
}

#clinics-module .facility-maps .link {
	margin-right: 1rem !important;
}

#clinics-module .md-list-item.md-list-item-split:nth-child(2n) {
	margin-left: 0 !important;
}

.md-physician-profile-top .md-column-content-wrap .md-custom-heading + div {
	margin-top: -10px;
}

.provider-list-rating {
	margin-bottom: 1rem;
}

.provider-list-rating .ratingsumm,
.provider-list-rating .ratingsumm > div {
	text-align: left !important;
	color: #07467d !important;
}

#physicians-module.physicians-collection.contemporary .md-physicians-fax,
#physicians-module.physicians-collection.contemporary .md-physicians-phone,
#physicians-module.physicians-collection.contemporary .physician-node .physician-listing-clinics .clinic,
#physicians-module.physicians-collection.contemporary .physician-node .physician-listing-specialties ul li a {
	color: #07467d !important;
}

.provider-list-rating .ratingsumm .ratecount {
	color: var( --body-font-color );
	font-size: 1.75rem;
	padding-left: 0;
}

.provider-list-rating .ratingsumm,
.provider-list-rating .ratingsumm .ratereview span {
	font-family: var( --body-font-family );
}

#specialties-module.specialty-node.contemporary .md-anchors-container .md-anchor-link a {
	padding: 1.25rem 7px !important;
	display: flex !important;
	align-items: center;
	justify-content: center;
}

#specialties-module.specialty-node.contemporary .md-anchors-container .md-anchor-link {
	display: flex;
	height: auto;
}

#specialties-module.specialty-node.contemporary #providers-section.tertiary {
	margin-top: 12px;
}

#rater8Widget_SuppressBadge {
	text-align: left !important;
}

#rater8Widget_SuppressBadge img {
	width: 68px !important;
}

#greetingcards-module .GreetingCardTemplatePreviewInside {
	font-size: 0.85rem !important;
}

/****************** Content Boxes ******************/
/***************************************************/
/* submission forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
	width: 80%;
	margin: 0 auto;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
	border: 1px solid #e4e6f1;
	border-radius: 0;
	min-height: 48px;
	margin-bottom: 0;
	padding: 12px 16px;
	transition: all 0.2s;
	font-size: 14px;
	line-height: 1.42857143;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

#submissionforms_module.homepage_contentbox .required_information {
	display: none;
}

#submissionforms_module.homepage_contentbox .cms_label label {
	color: #160042;
	letter-spacing: -0.01em;
	margin-bottom: 8px;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
}

@media (max-width: 767px) {
	#submissionforms_module.homepage_contentbox {
		width: 60%;
	}

	#submissionforms_module.homepage_contentbox .cms_buttons {
		padding: 5px 0 5px 0;
		text-align: center;
	}
}

/***************** Custom Landing Page ***************/
/***************************************************/
/* Custom Landing Page */
/* Containers */
@media (min-width: 992px) {
	.grid-container {
		padding-bottom: 40px;
	}
}

.grid-container .contentbox-container.default {
	background-color: #fff;
}

.grid-container .section.mod--steps.blue,
.grid-container .section.mod--reviews {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-gap: 25px 25px;
	width: 100%;
	grid-column: 1 / span 12;
	margin-left: auto;
	margin-right: auto;
	padding-left: 60px;
	padding-right: 60px;
	max-width: 1220px;
	padding-top: 60px;
}

.grid-container .section-slide.mod--3,
.grid-container .section-slide.white {
	width: 100%;
	max-width: 100%;
}

.grid-container .locations-section {
	height: auto;
	min-height: 447.953px;
	width: 100%;
}

.grid-container .locations-section.no-image {
	display: block;
	min-height: auto;
}

.grid-container .locations-section.no-image .heading-wrapper {
	width: 100%;
	padding-right: 0px;
	justify-content: center;
	align-items: center;
}

.grid-container .locations-section.no-image .heading-wrapper .text-block {
	max-width: 100%;
	width: 100%;
}

.grid-container .mask {
	overflow: visible;
}

.grid-container .w-slider-mask.default-slideshow {
	height: 600px;
	background-color: #dddddd;
}

.grid-container .content-box.slideshow.default {
	position: relative;
}

@media (min-width: 992px) {
	.grid-container .map-wrapper {
		height: 447.953px;
	}
}

@media (max-width: 991px) {
	.grid-container .locations-section {
		min-height: auto;
	}

	.grid-container .section.mod--steps.blue,
	.grid-container .section.mod--reviews {
		padding-bottom: 30px;
	}
}

@media (max-width: 767px) {
	.grid-container .w-slider-mask.default-slideshow {
		background-color: #fff;
	}

	.grid-container .slick-dots {
		display: none !important;
	}

	.grid-container .section.mod--steps.blue,
	.grid-container .section.mod--reviews {
		padding-top: 30px;
	}

	.grid-container .section-slide.mod--3,
	.grid-container .section-slide.white {
		padding-top: 0px;
	}
}

@media (max-width: 479px) {
	.grid-container .btnwrap.slideshow {
		margin-top: 20px;
	}

	.grid-container .section.mod--steps.blue,
	.grid-container .section.mod--reviews {
		display: block;
	}

	.grid-container .section-slide.mod--3,
	.grid-container .section-slide.white {
		padding-left: 30px;
		padding-right: 30px;
	}

	.grid-container .contentbox-container .content-box {
		margin-bottom: 20px;
	}

	.grid-container {
		padding-bottom: 40px;
	}
}
