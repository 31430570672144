.collapsible-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 24px;
	padding: 1.25rem 1.5rem;
	border-style: solid;
	border-width: 1px;
	border-color: #d9dbe980;
	border-radius: 5px;
	box-shadow: none;
	-webkit-transition: border-color 200ms ease, box-shadow 200ms ease;
	transition: border-color 200ms ease, box-shadow 200ms ease;
	text-align: left !important;
}

.collapsible-content {
	text-align: left !important;
}

.collapsible-header:hover {
	cursor: pointer;
}

.collapsible-header:after {
	content: '\002B';
	color: #777;
	font-weight: bold;
	float: right;
	margin-left: 5px;
	font-size: 20px;
}

.collapsible-header.open:after {
	content: '\2212';
}
